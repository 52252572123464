import React from 'react';
import { useLocation } from 'react-router-dom';

import GnbElement from '../gnb-element/gnb-element.component';

import { GnbFullContainer, VertigoLogoHome } from './gnb-full.styles';

const GnbFull = ({ isReachedTop, pageYOffset }) => {
  const { pathname } = useLocation();

  return (
    <GnbFullContainer
      isReachedTop={isReachedTop}
      pageYOffset={pageYOffset}
      pathname={pathname}
    >
      <VertigoLogoHome to='/'></VertigoLogoHome>
      <GnbElement />
    </GnbFullContainer>
  );
};

export default GnbFull;
