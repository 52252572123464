import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectNewsItems } from '../../redux/posts/posts.selector';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import NewsCard from '../news-card/news-card.component';
import Arrows from '../slick-arrows/arrow-component';

import { NewsSlickSliderContainer } from './news-slick-slider.styles';

const settings = {
  infinite: false,
  dots: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  nextArrow: <Arrows direction='left' />,
  prevArrow: <Arrows direction='right' />,
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1210,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        arrows: false,
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 475,
      settings: {
        arrows: false,
        slidesToShow: 1,
        variableWidth: true,
      },
    },
  ],
};

const NewsSlickSlider = ({ newsItems, size }) => {
  return (
    <NewsSlickSliderContainer>
      <Slider {...settings}>
        {newsItems.map(({ id, ...otherCollectionProps }) => (
          <NewsCard key={id} {...otherCollectionProps} size={size} />
        ))}
      </Slider>
    </NewsSlickSliderContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  newsItems: selectNewsItems,
});

export default connect(mapStateToProps)(NewsSlickSlider);
