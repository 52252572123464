import styled from 'styled-components';

export const ArrowContainer = styled.div`
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 10px;
  border: solid 2px #1c1c1c;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.2 : 1)};
  > svg {
    width: 25px;
    height: 25px;
  }
`;
