import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import RecruitmentCircle from '../recruitment-circle/recruitment-circle.component';

import { selectCircleData } from '../../redux/careersPage/careersPage.selector';

import {
  RecruitmentDiagramContainer,
  HorizontalLine,
  OrangeVerticalLine,
} from './recruitment-diagram.styles';

const RecruitmentDiagram = ({ circleData }) => {
  return (
    <RecruitmentDiagramContainer>
      <OrangeVerticalLine />
      <HorizontalLine />
      {circleData.map(({ size, number, title }, index) => (
        <RecruitmentCircle
          key={index}
          size={size}
          number={number}
          title={title}
        />
      ))}
    </RecruitmentDiagramContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  circleData: selectCircleData,
});

export default connect(mapStateToProps)(RecruitmentDiagram);
