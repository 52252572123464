import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, animateScroll as scroll } from 'react-scroll';

import {
  NavToHireSectionContainer,
  DownArrow,
} from './nav-to-hire-section.styles';

import downArrow from '../../assets/images/acsy/ic_arrow_careers.png';

const NavToHireSection = ({ isVisible }) => {
  return (
    <Link to='hiringSection' smooth={true} duration={300}>
      <NavToHireSectionContainer isVisible={isVisible}>
        <FormattedMessage id='careers_section_hire_button' />
        <DownArrow src={downArrow} alt='arrow' />
      </NavToHireSectionContainer>
    </Link>
  );
};

export default NavToHireSection;
