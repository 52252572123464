import styled from 'styled-components';

export const NewsCardImgWrapper = styled.div`
  width: 100%;
  height: 10.9375rem;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 1.25rem 1.25rem 0 0;
  background-image: ${({ bgImg }) => `url(${bgImg})`};
  transition: all 0.3s ease-in-out;
  @media ${({ theme }) => theme.mobile} {
    display: none;
  }
`;

export const NewsCardContainer = styled.div`
  position: relative;
  width: 100%;
  height: 29.6875rem;
  border-radius: 1.25rem;
  background-color: #ffffff;
  box-shadow: 0.125rem 0.3125rem 0.9375rem 0rem rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: ${({ size }) => (size === 'medium' ? '22.5rem' : '29.375rem')};
  margin-top: ${({ size }) => (size === 'medium' ? '30px' : '0')};
  margin-bottom: ${({ size }) => (size === 'medium' ? '30px' : '0')};
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  &:hover {
    transform: scale(1.05);
    box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.3);
    ${NewsCardImgWrapper} {
      transform: scale(1.1);
    }
  }
  @media ${({ theme }) => theme.tablet} {
    max-width: ${({ size }) => (size === 'medium' ? '320px' : '286px')};
  }
  @media ${({ theme }) => theme.mobile} {
    align-self: center;
    justify-self: center;
    max-width: ${({ size }) => (size === 'medium' ? '200px' : '194px')};
    height: ${({ size }) => (size === 'medium' ? '300px' : '345px')};
    margin-top: 5px;
    margin-bottom: 15px;
    margin-right: 15px;
  }
`;

export const NewsCardContentWrapper = styled.div`
  position: relative;
  padding: ${({ bgImg }) =>
    bgImg ? '1.4375rem 1.875rem 1.875rem 1.875rem;' : '1.875rem'};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //height: 300px;
  @media ${({ theme }) => theme.mobile} {
    padding: 18px 16px;
  }
`;

export const NewsCardTag = styled.div`
  top: ${({ bgImg }) => (bgImg ? '-0.840625rem;' : 'auto')};
  position: ${({ bgImg }) => (bgImg ? 'absolute' : 'relative')};
  height: 1.5625rem;
  width: 4rem;
  border-radius: 0.3125rem;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.notoSansKr};
  font-size: 0.8125rem;
  font-weight: bold;
  line-height: 1.1875rem;
  color: #fff;
  background-color: ${({ tag }) => (tag === 'news' ? '#FF8300' : '#FF2E2E')};
  @media ${({ theme }) => theme.mobile} {
    position: relative;
    top: auto;
  }
`;

export const NewsTitle = styled.div`
  white-space: nowrap;
  min-height: 3.125rem;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: ${({ theme }) => theme.notoSansKr};
  font-size: 1.5625rem;
  font-weight: 500;
  line-height: 2.0625rem;
  color: #1c1c1c;
  @media ${({ theme }) => theme.mobile} {
    font-size: 18px;
  }
`;

export const NewsSourceContainer = styled.div`
  margin-top: ${({ size }) => (size === 'medium' ? '0.3125rem' : '-10px')};
  width: 100%;
  height: 1.25rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const NewsPublisher = styled.div`
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.notoSansKr};
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.25rem;
  color: #858585;
  @media ${({ theme }) => theme.mobile} {
    font-size: 12px;
  }
`;

export const NewsData = styled.div`
  margin: 0.625rem;
  font-family: ${({ theme }) => theme.notoSansLight};
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #858585;
  @media ${({ theme }) => theme.mobile} {
    font-size: 12px;
  }
`;

export const NewsDesc = styled.div`
  width: 100%;
  //height: 95px;
  overflow: hidden;
  margin-top: 14px;
  font-family: ${({ theme }) => theme.notoSansKr};
  font-size: 1rem;
  line-height: 1.5rem;
  > p {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    > strong {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  @media ${({ theme }) => theme.mobile} {
    font-size: 12px;
    line-height: 18px;
  }
`;

export const NewsButton = styled.a`
  position: absolute;
  bottom: 30px;
  left: 30px;
  display: block;
  height: 1.875rem;
  width: 6.625rem;
  border: 1px solid #858585;
  border-radius: 5px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.notoSansLight};
  font-size: 0.875rem;
  line-height: 1.1875rem;
  color: #858585;
  @media ${({ theme }) => theme.mobile} {
    width: 5rem;
    font-size: 10px;
  }
`;
