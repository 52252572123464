import styled from 'styled-components';

export const MainPageContainer = styled.div`
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
`;

export const MainPageContentContainer = styled.div`
  padding-left: 8rem;
  padding-top: 9.25rem;
  overflow-x: visible;
  width: 100%;
  max-width: 110rem;

  @media ${({ theme }) => theme.laptop} {
    padding-top: 4rem;
  }
  @media ${({ theme }) => theme.mobile} {
    padding-left: 34px;
  }
`;
