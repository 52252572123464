import styled, { css } from 'styled-components';

export const AnalogClockContainer = styled.div`
  width: 150px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  @media ${({ theme }) => theme.mobile} {
    padding: 7px 0;
    width: 100%;
    max-width: 267px;
    flex-direction: row;
    justify-content: flex-start;
    border-top: 1px solid #f0f0f0;
    &:last-child {
      border-bottom: 1px solid #f0f0f0;
    }
  }
`;

export const ClockWrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: block;
  background-color: #fff;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  border: 3px solid #f0f0f0;
  @media ${({ theme }) => theme.mobile} {
    height: 80px;
    width: 80px;
  }
`;

export const ClockContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ClockCenter = styled.div`
  height: 8px;
  width: 8px;
  background-color: #1c1c1c;
  border-radius: 50%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
`;

export const ClockAmPm = styled.div`
  position: absolute;
  font-family: ${({ theme }) => theme.notoSansBold};
  font-size: 20px;
  line-height: 27px;
  color: #f0f0f0;
  bottom: 12px;
  margin: auto;
  @media ${({ theme }) => theme.mobile} {
    font-size: 14px;
    bottom: 5px;
  }
`;

const indicator = css`
  transform-origin: bottom;
  outline: 1px solid transparent; // Firefox fix on rotation
  position: absolute;
  bottom: 50%;
`;

export const ClockHourIndicator = styled.div`
  height: 40px;
  width: 3px;
  border-radius: 2.5px;
  background-color: #1c1c1c;
  box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.3);
  transform: ${({ hoursDegrees }) =>
    hoursDegrees ? `rotateZ(${hoursDegrees}deg)` : ''};
  ${indicator}
  @media ${({ theme }) => theme.mobile} {
    height: 20px;
  }
`;

export const ClockMinutesIndicator = styled.div`
  height: 50px;
  width: 2px;
  border-radius: 2.5px;
  background-color: #54565a;
  box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.3);
  transform: ${({ minutesDegrees }) =>
    minutesDegrees ? `rotateZ(${minutesDegrees}deg)` : ''};
  ${indicator}
  @media ${({ theme }) => theme.mobile} {
    height: 30px;
  }
`;

export const ClockSecondIndicator = styled.div`
  height: 50px;
  width: 0.5px;
  border-radius: 2.5px;
  background-color: #858585;
  transform: ${({ secondsDegrees }) =>
    secondsDegrees ? `rotateZ(${secondsDegrees}deg)` : ''};
  ${indicator}
  @media ${({ theme }) => theme.mobile} {
    height: 30px;
  }
`;

export const ClockCity = styled.div`
  margin-top: 11px;
  font-family: ${({ theme }) => theme.notoSansMedium};
  line-height: 19px;
  color: #54565a;
  @media ${({ theme }) => theme.mobile} {
    width: 100px;
    margin-top: 0;
    margin-left: 15px;
  }
`;

export const PinWrapper = styled.div`
  display: none;
  @media ${({ theme }) => theme.mobile} {
    display: block;
  }
`;
