import styled, { css } from "styled-components";

import vertigoLogo from "../../assets/images/acsy/vertigogames_b.png";

export const GameDetailsContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  background-color: #fff;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 38px;
  box-sizing: content-box;
  height: 100%;
  @media ${({ theme }) => theme.mobile} {
    padding-top: 0;
  }
`;

export const GameDetailsHeader = styled.div`
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const GameDetailsHeaderLogo = styled.div`
  width: 155px;
  height: 31px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url(${vertigoLogo});
`;

export const CloseButton = styled.button`
  &:after {
    font-weight: 300;
    font-size: 50px;
    color: #000;
    opacity: 0.7;
    content: "\\d7"; /* This will render the 'X' */
  }
`;

export const GameDetailsMainImageWrapper = styled.div`
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 31px;
`;

export const GameDetailsMainImage = styled.img`
  width: 100%;
`;

const titleStyle = css`
  font-family: ${(props) => props.theme.notoSansMedium};
  font-size: 1rem;
  color: #1c1c1c;
  margin-right: 10px;
  text-transform: uppercase;
`;

const descStyle = css`
  font-family: ${(props) => props.theme.notoSansLight};
  font-size: 1rem;
  color: #1c1c1c;
`;

export const GameDetailsContent = styled.div`
  margin-top: 35px;
  width: 100%;
  max-width: 920px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const GameDetailsRowWrapper = styled.div`
  margin-top: 10px;
  width: 100%;
  text-align: left;
  display: flex;
`;
export const GameDetailsRowTitle = styled.div`
  ${titleStyle}
`;

export const GameDetailsRowDesc = styled.div`
  ${descStyle}
`;

export const GameDetailsDesc = styled.div`
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #ddd;
  font-family: ${({ theme }) => theme.notoSansKr};
  font-weight: 300;
  line-height: 30px;
  font-size: 1rem;
  color: #1c1c1c;
  width: 100%;
  max-width: 920px;
`;

export const GameDeatilsWebsiteWrapper = styled.div`
  margin-top: 60px;
  width: 100%;
  max-width: 920px;
  display: flex;
  justify-content: center;
  @media ${({ theme }) => theme.mobile} {
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
  }
`;

export const GameDetailsWebsite = styled.a`
  position: relative;
  width: 100%;
  max-width: 400px;
  height: 44px;
  border-radius: 5px;
  border: 1px solid #1c1c1c;
  font-family: ${({ theme }) => theme.notoSansMedium};
  font-size: 1rem;
  color: #1c1c1c;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  &:nth-child(2) {
    margin-left: 40px;
  }
  &:nth-child(3) {
    margin-left: 40px;
  }
  @media ${({ theme }) => theme.mobile} {
    &:nth-child(2) {
      margin-top: 15px;
      margin-left: 0px;
    }
    &:last-child {
      margin-top: 15px;
      margin-bottom: 15px;
      margin-left: 0px;
    }
  }
`;

export const BtnArrow = styled.img`
  position: absolute;
  right: 16px;
`;

export const SnsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
`;
