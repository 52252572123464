import React from 'react';
import { useIntl } from 'react-intl';
import parse from 'html-react-parser';

import { BoxContainer, BoxTitle, BoxImage } from './about-box.styles';

const AboutBox = ({ backgroundImage, index }) => {
  const intl = useIntl();
  const boxTitle = intl.messages['six_reason_data'];
  return (
    <BoxContainer>
      <BoxTitle>{parse(boxTitle[index].title)}</BoxTitle>
      <BoxImage backgroundImage={backgroundImage} />
    </BoxContainer>
  );
};

export default AboutBox;
