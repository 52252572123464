export const GlobalModalStyle = `
  .ReactModal__Html--open,
  .ReactModal__Body--open {
    overflow: hidden;
  }
  
  .ReactModal__Overlay {
    position: fixed;
    z-index: 999999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3) !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .ReactModal__Content {
    background: white;
    width: 100%;
    max-width: 1336px;
    max-width: calc(100vw - 2rem);
    max-height: calc(100vh - 2rem);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.25);
    overflow-y: auto;
    position: relative;
  }
  @media screen and (max-width: 600px) {
    .ReactModal__Content {
      max-height: 100vh;
    }
  }






`;
