import styled from 'styled-components';

export const StepListWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  &:nth-child(2) {
    width: 90px;
    left: calc(50% - 355px);
    top: -42px;
    margin: 0 auto;
  }
  &:nth-child(4) {
    width: 129px;
    left: calc(50% - 180px);
    top: 45px;
    margin: 0 auto;
  }
  &:nth-child(6) {
    width: 162px;
    left: calc(50% + 75px);
    top: 10px;
    margin: 0 auto;
  }
  @media ${({ theme }) => theme.tablet} {
    &:nth-child(2) {
      left: calc(50% - 278px);
    }
    &:nth-child(4) {
      left: calc(50% - 140px);
    }
    &:nth-child(6) {
      left: calc(50% + 60px);
    }
  }
  @media ${({ theme }) => theme.mobile} {
    position: relative;
    &:nth-child(2) {
      left: auto;
      top: auto;
      margin: 0;
    }
    &:nth-child(4) {
      left: auto;
      top: auto;
      margin: 0;
    }
    &:nth-child(6) {
      left: auto;
      top: auto;
      margin: 0;
    }
  }
`;

export const StepText = styled.div`
  font-family: ${({ theme }) => theme.notoSansKr};
  font-size: 1rem;
  font-weight: 300;
  line-height: 24px;
  color: #858585;
  @media ${({ theme }) => theme.tablet} {
    font-size: 14px;
    line-height: 20px;
  }
`;
