import styled from 'styled-components';

export const NumberBoxContainer = styled.div`
  margin-top: 9.25rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  @media ${({ theme }) => theme.tablet} {
    grid-template-columns: 1fr 1fr;
  }
  @media ${({ theme }) => theme.tablet} {
    margin-top: 52px;
    grid-template-columns: 1fr;
  }
`;

export const NumberBoxTopWrapper = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  @media ${({ theme }) => theme.tablet} {
    margin-bottom: 16px;
  }
`;

export const NumberText = styled.div`
  font-family: ${({ theme }) => theme.notoSansLight};
  font-size: 4.375rem;
  line-height: 6rem;
  color: #ffc835;
  @media ${({ theme }) => theme.tablet} {
    font-size: 55px;
    line-height: 75px;
  }
`;

export const NumberBox = styled.div`
  align-self: flex-start;
  justify-self: start;
  width: 15.625rem;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @media ${({ theme }) => theme.tablet} {
    justify-self: center;
  }
  &:nth-child(1) {
    ${NumberBoxTopWrapper} {
      ${NumberText} {
        opacity: 0.4;
      }
    }
  }
  &:nth-child(2) {
    ${NumberBoxTopWrapper} {
      ${NumberText} {
        opacity: 0.7;
      }
    }
  }
  &:nth-child(3) {
    ${NumberBoxTopWrapper} {
      ${NumberText} {
        opacity: 1;
      }
    }
  }
`;

export const NumberTitle = styled.div`
  padding-bottom: 10px;
  font-family: ${(props) => props.theme.notoSansKr};
  font-size: 2.125rem;
  font-weight: 300;
  line-height: 3.125rem;
  color: #1c1c1c;
  @media ${({ theme }) => theme.tablet} {
    font-size: 28px;
    line-height: 41px;
  }
`;

export const NumberDesc = styled.div`
  width: 15.625rem;
  font-family: ${(props) => props.theme.notoSansKr};
  font-size: 1rem;
  font-weight: 300;
  line-height: 2.3125rem;
  color: #858585;
  @media ${({ theme }) => theme.tablet} {
    font-size: 14px;
    line-height: 26px;
  }
`;
