import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ModalWindow from '../../components/modal-window/modal-window.component';
import GameDetails from '../../components/game-details/game-details.component';

import { selectSortedGameItems } from '../../redux/games/games.selector';

import PageTopImage from '../../components/page-top-image/page-top-image.component';
import GamesCardSmall from '../../components/games-card-small/games-card-small.component';

import { GamesPageContainer, GamesContainer } from './GamesPage.styles';

const GamesPage = ({ match, gameItems }) => {
  return (
    <GamesPageContainer>
      <PageTopImage path={match.path} />
      <GamesContainer>
        {gameItems.map((game, index) => (
          <GamesCardSmall key={game.id} game={game} index={index} />
        ))}
      </GamesContainer>
      <ModalWindow>
        <GameDetails gameItems={gameItems} />
      </ModalWindow>
    </GamesPageContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  gameItems: selectSortedGameItems,
});

export default connect(mapStateToProps)(GamesPage);
