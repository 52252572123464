import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';

import { selectMapLocationList } from '../../redux/contactsPage/contactsPage.selector';

import PageSectionTitle from '../page-section-title/page-section-title.component';
import ContactsMapPin from '../contacts-map-pin/contacts-map-pin.component';

import {
  ContactsMapContainer,
  ContactsMapDesc,
  ContactsMapWrapper,
  ContactsLocationContainer,
} from './contacts-map.styles';

const ContactsMap = ({ mapLocationList }) => {
  return (
    <ContactsMapContainer>
      <PageSectionTitle
        title='contactpage_section_2_title'
        linePosition='center'
      />
      <ContactsMapDesc>
        <FormattedMessage id='contactpage_section_2_desc' />
      </ContactsMapDesc>
      <ContactsMapWrapper>
        <ContactsLocationContainer>
          {mapLocationList.map(({ country, ...otherProps }) => (
            <ContactsMapPin key={country} country={country} {...otherProps} />
          ))}
        </ContactsLocationContainer>
      </ContactsMapWrapper>
    </ContactsMapContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  mapLocationList: selectMapLocationList,
});

export default connect(mapStateToProps)(ContactsMap);
