import { createSelector } from 'reselect';

const selectGames = (state) => state.games;

export const selectGameItems = createSelector(
  [selectGames],
  (games) => games.gameItems
);
export const selectDevelopGames = createSelector(
  [selectGameItems],
  (gameItems) =>
    gameItems.filter((gameItem) => gameItem.category.includes('develop'))
);

export const selectPublishGames = createSelector(
  [selectGameItems],
  (gameItems) =>
    gameItems.filter((gameItem) => gameItem.category.includes('publish'))
);

export const selectSortedGameItems = createSelector([selectGames], (games) =>
  games.gameItems.sort((a, b) => a.id - b.id)
);
