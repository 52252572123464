import CareersPageActionTypes from './careersPage.types';

export const enableOffice = (index) => ({
  type: CareersPageActionTypes.ENABLE_OFFICE,
  payload: index,
});

export const handleMobileFilterMenu = () => ({
  type: CareersPageActionTypes.HANDLE_MOBILE_FILTER_MENU,
});
