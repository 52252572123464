import { createSelector } from 'reselect';

const selectContactsPage = (state) => state.contacts;

export const selectEmailAddressList = createSelector(
  [selectContactsPage],
  (contactsPage) => contactsPage.emailAddressList
);

export const selectMapLocationList = createSelector(
  [selectContactsPage],
  (contactsPage) => contactsPage.mapLocationList
);
