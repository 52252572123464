import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// reducers
import gamesReducer from './games/games.reducer';
import postsReducer from './posts/posts.reducer';
import pageTopContentReducer from './pageTopContents/pageTopContents.reducer';
import aboutPageReducer from './aboutPage/aboutPage.reducer';
import localeReducer from './locales/locales.reducer';
import modalReducer from './modal/modal.reducer';
import careersReducer from './careersPage/careersPage.reducer';
import contactsReducer from './contactsPage/contactsPage.reducer';
import gnbReducer from './gnb/gnb.reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['news'],
};

const rootReducer = combineReducers({
  games: gamesReducer,
  posts: postsReducer,
  pageTopContents: pageTopContentReducer,
  aboutPage: aboutPageReducer,
  locales: localeReducer,
  modal: modalReducer,
  careers: careersReducer,
  contacts: contactsReducer,
  gnb: gnbReducer,
});

export default persistReducer(persistConfig, rootReducer);
