import styled from 'styled-components';

export const HireAccordionMenuWrapper = styled.div`
  position: relative;
  margin-top: 20px;
  cursor: pointer;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  @media ${({ theme }) => theme.mobile} {
    width: 240px;
    height: 115px;
  }
`;

export const MobileAccordionMenuWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: transparent;
  display: none;
  border-radius: 5px;
  @media ${({ theme }) => theme.mobile} {
    display: block;
  }
`;

export const AccordionHeader = styled.div`
  border-radius: 20px;
  padding-left: 36px;
  width: 100%;
  background-color: #fff;
  height: 90px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  @media ${({ theme }) => theme.mobile} {
    flex-direction: column;
    padding-left: 0;
  }
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  position: relative;
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media ${({ theme }) => theme.tablet} {
    width: 50%;
  }
  @media ${({ theme }) => theme.mobile} {
    width: 100%;
    height: 46px;
  }
`;

export const MobileHeaderArrow = styled.img`
  position: absolute;
  right: 12.5px;
  display: none;
  @media ${({ theme }) => theme.mobile} {
    display: block;
  }
`;

export const HeaderTitle = styled.div`
  width: 100%;
  font-family: ${({ theme }) => theme.notoSansKr};
  font-weight: 500;
  line-height: 27px;
  color: #1c1c1c;
  font-size: 18px;
  @media ${({ theme }) => theme.tablet} {
    font-size: 14px;
  }
  @media ${({ theme }) => theme.mobile} {
    width: 90%;
    height: 46px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    padding-left: 20px;
  }
`;

export const BodyWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  @media ${({ theme }) => theme.mobile} {
    flex-direction: column;
    background-color: #f6f6f6;
    padding-left: 20px;
  }
`;

export const HeaderDepartment = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: ${({ theme }) => theme.notoSansLight};
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  color: #858585;
  @media ${({ theme }) => theme.tablet} {
    font-size: 14px;
  }
  @media ${({ theme }) => theme.mobile} {
    width: 100%;
  }
`;

const getLocationTextColor = ({ location }) => {
  if (location === 'Seoul') {
    return '#EE3800';
  }
  if (location === 'Vancouver') {
    return '#0082EE';
  }
  return '#b847d0';
};

export const HeaderLocation = styled.div`
  width: 50%;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: ${({ theme }) => theme.notoSansLight};
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: ${getLocationTextColor};
  @media ${({ theme }) => theme.tablet} {
    font-size: 14px;
  }
  @media ${({ theme }) => theme.mobile} {
    width: 100%;
  }
`;

export const ArrowWrapper = styled.div`
  width: 10%;
  transition: transform 0.2s;
  @media ${({ theme }) => theme.mobile} {
    display: none;
  }
`;

export const ArrowImage = styled.img`
  transform: rotate(180deg);
`;

export const AccordionBody = styled.div`
  width: 100%;
  max-width: 1000px;
  border-radius: 0 0 5px 5px;
  background-color: #f6f6f6;
  z-index: ${({ isMenuOpen }) => (isMenuOpen ? 1 : -1)};
  height: ${({ isMenuOpen }) => (isMenuOpen ? '100%' : 0)};
  opacity: ${({ isMenuOpen }) => (isMenuOpen ? 1 : 0)};
  transition: opacity 0.3s ease-out;
  @media ${({ theme }) => theme.mobile} {
    display: none;
  }
`;

export const AccordionBodyContents = styled.div`
  padding-top: 24px;
  padding-left: 36px;
  padding-bottom: 35px;
  width: 100%;
  max-width: 817px;
  font-family: ${({ theme }) => theme.notoSansLight};
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  color: #1c1c1c;
  display: ${({ isMenuOpen }) => (isMenuOpen ? 'block' : 'none')};
  > p {
    word-break: break-all;
  }
  @media ${({ theme }) => theme.tablet} {
    padding-right: 36px;
  }
`;

export const ApplyButton = styled.a`
  margin-top: 35px;
  height: 44px;
  width: 170px;
  border: 1px solid #1c1c1c;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.notoSansMedium};
  font-size: 1rem;
  line-height: 22px;
  color: #1c1c1c;
`;

export const ButtonRightArrow = styled.img`
  margin-left: 20px;
  margin-right: 17px;
`;
