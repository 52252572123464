import styled from 'styled-components';
import mainBackgroundImage from '../../assets/images/bg/bg_main_home.jpg';

export const MainImageContainer = styled.div`
  //scroll-snap-align: center;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

export const BackgroundContainer = styled.div`
  width: 100%;
  height: 100%;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-image: url(${mainBackgroundImage});
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ scrollY }) =>
    scrollY < 1.2 ? `transform: scale(${scrollY})` : 'transform: scale(1)'};
  @media ${({ theme }) => theme.mobile} {
    transform: none;
  }
`;

export const MaskLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
`;

export const MainHeader = styled.div`
  z-index: 2;
  width: 100%;
  max-width: 50rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const MainHeaderTitle = styled.h1`
  z-index: 2;
  text-align: center;
  width: 100%;
  font-family: 'Noto Sans Bold';
  font-size: 3.125rem;
  line-height: 5.375rem;
  color: #fff;
  @media ${({ theme }) => theme.tablet} {
    font-size: 40px;
    line-height: 55px;
  }
  @media ${({ theme }) => theme.mobile} {
    width: 80%;
    font-size: 25px;
    line-height: 35px;
  }
`;

export const MainHeaderSubtitle = styled.h6`
  z-index: 2;
  margin-top: 60px;
  width: 100%;
  text-align: center;
  font-family: 'Noto Sans Bold';
  font-size: 1rem;
  line-height: 1.375rem;
  color: #ff8300;
`;
