import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useIntl } from 'react-intl';
import { Spring, animated } from 'react-spring';
import parse from 'html-react-parser';

import { selectCharacterImages } from '../../redux/aboutPage/aboutPage.selector';

import {
  HistoryCharacterContainer,
  CharacterDialogue,
  CharacterImage,
} from './history-character.styles';

const HistoryCharacters = ({ characterImages, isVisible }) => {
  const intl = useIntl();
  const messages = intl.messages['character_data'];
  return (
    <HistoryCharacterContainer>
      {isVisible
        ? characterImages.map((image, index) => (
            <Spring
              key={index}
              delay={0 + index * 1000}
              from={{ opacity: 0, y: 100 }}
              to={{ opacity: 1, y: 0 }}
            >
              {(styles) => (
                <animated.div style={styles}>
                  <CharacterDialogue>
                    {parse(messages[index].dialogue)}
                  </CharacterDialogue>
                  <CharacterImage src={image} alt='character' />
                </animated.div>
              )}
            </Spring>
          ))
        : null}
    </HistoryCharacterContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  characterImages: selectCharacterImages,
});

export default connect(mapStateToProps)(HistoryCharacters);
