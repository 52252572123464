import React, { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';

//selectors
import {
  selectSixServices,
  selectHistories,
} from '../../redux/aboutPage/aboutPage.selector';

// components
import PageTopImage from '../../components/page-top-image/page-top-image.component';
import PageSectionTitle from '../../components/page-section-title/page-section-title.component';
import AboutBox from '../../components/about-box/about-box.component';
import PapayaPlayButton from '../../components/papaya-play-button/papaya-play-button.component';
import HistoryTable from '../../components/history-table/history-table.component';

// styles
import {
  AboutUsContainer,
  AboutUsDescriptionContainer,
  AboutUsDescription,
  ServicingContainer,
  ServicingDescription,
  AboutUsSixBoxContainer,
  AboutUsHistoryContainer,
  HistoryInnerContainer,
} from './AboutUsPage.styles';

const AboutUsPage = ({ match, sixServices }) => {
  // active history animation for only once
  const [isHistoryActive, setHistoryActiveOnce] = useState(true);
  const handleVisiblity = (isVisible) => {
    if (isVisible) {
      setHistoryActiveOnce(false);
    }
  };
  return (
    <AboutUsContainer>
      <PageTopImage path={match.path} />
      <AboutUsDescriptionContainer>
        <PageSectionTitle title='about_section_1_title' />
        <AboutUsDescription>
          <FormattedMessage
            id='about_section_1_desc'
            values={{ linebreak: <br />, b: (...chunks) => <b>{chunks}</b> }}
          />
        </AboutUsDescription>
      </AboutUsDescriptionContainer>
      {/* section two */}
      <ServicingContainer>
        <PageSectionTitle color='green' title='about_section_2_title' />
        <ServicingDescription>
          <FormattedMessage
            id='about_section_2_desc'
            values={{ b: (...chunks) => <b>{chunks}</b> }}
          />
        </ServicingDescription>
        <AboutUsSixBoxContainer>
          {sixServices.map(({ image }, index) => (
            <AboutBox key={index} backgroundImage={image} index={index} />
          ))}
        </AboutUsSixBoxContainer>
        <PapayaPlayButton />
      </ServicingContainer>
      {/** section three - history */}
      <AboutUsHistoryContainer>
        <VisibilitySensor
          active={isHistoryActive}
          partialVisibility={true}
          delayedCall={true}
          onChange={handleVisiblity}
        >
          {({ isVisible }) => (
            <HistoryInnerContainer>
              <PageSectionTitle title='about_section_3_title' />
              <HistoryTable isVisible={isVisible} />
            </HistoryInnerContainer>
          )}
        </VisibilitySensor>
      </AboutUsHistoryContainer>
    </AboutUsContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  sixServices: selectSixServices,
  histories: selectHistories,
});

export default connect(mapStateToProps)(AboutUsPage);
