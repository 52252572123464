import styled from 'styled-components';
import mapIndicator from '../../assets/images/acsy/ic_location.png';

export const MapPinImage = styled.div`
  z-index: 1;
  width: 61px;
  height: 70px;
  margin: 0 auto;
  background-image: url(${mapIndicator});
`;

export const MobileLinkButton = styled.a`
  position: absolute;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  @media ${({ theme }) => theme.mobile} {
    display: block;
  }
`;

export const ContactsMapPinContainer = styled.div`
  position: absolute;
  @media ${({ theme }) => theme.mobile} {
    position: relative;
  }
`;

export const ContactMapDetailWrapper = styled.div`
  position: absolute;
  width: 234px;
  background-color: #1c1c1c;
  padding: 15px;
  box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.3);
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  &:after {
    content: '';
    position: absolute;
  }
  @media ${({ theme }) => theme.mobile} {
    display: none;
  }
`;

export const Location = styled.div`
  font-family: ${(props) => props.theme.notoSansMedium};
  font-size: 12px;
  line-height: 17px;
  color: #fff;
`;

export const Address = styled.div`
  margin-top: 18px;
  font-family: ${(props) => props.theme.notoSansMedium};
  font-size: 12px;
  line-height: 17px;
  color: #fff;
`;

export const Email = styled.a`
  margin-top: 25px;
  font-family: ${(props) => props.theme.notoSansRegular};
  font-size: 12px;
  line-height: 17px;
  color: #ff8300;
`;

export const Contact = styled.a`
  margin-top: 25px;
  font-family: ${(props) => props.theme.notoSansRegular};
  font-size: 12px;
  line-height: 17px;
  color: #ff8300;
`;

export const Phone = styled.div`
  margin-top: 25px;
  font-family: ${(props) => props.theme.notoSansRegular};
  font-size: 12px;
  line-height: 17px;
  color: #fff;
`;

export const MapButton = styled.a`
  margin-top: 18px;
  height: 30px;
  width: 106px;
  border: 1px solid #ffffff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${(props) => props.theme.notoSansLight};
  font-size: 14px;
  line-height: 19px;
  color: #fff;
`;
