import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { selectIsPostFetching } from '../../redux/posts/posts.selector';

import WithSpinner from '../with-spinner/with-spinner.component';
import NewsContent from './news-content.component';

const mapStateToProps = createStructuredSelector({
  isLoading: selectIsPostFetching,
});

const NewsContentContainer = compose(
  connect(mapStateToProps),
  WithSpinner
)(NewsContent);

export default NewsContentContainer;
