import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

import AnalogClock from '../analog-clock/analog-clock.component';

import {
  ContactsTimeZoneContainer,
  TimeZoneInnerWrapper,
} from './contacts-time-zone.styles';

const ContactsTimeZone = () => {
  useEffect(() => {
    const interval = setInterval(() => {
      tick();
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  const [timeZone, setTimeZone] = useState([]);

  const tick = () => {
    const momentSeoul = moment().tz('Asia/Seoul');
    const momentVancouver = moment().tz('America/Vancouver');
    const momentSingapore = moment().tz('Asia/Singapore');
    const WORLDTIMES = [
      {
        city: 'Seoul, Korea',
        hours: momentSeoul.format('H'),
        minutes: momentSeoul.format('mm'),
        seconds: momentSeoul.format('ss'),
        ampm: momentSeoul.format('A'),
      },
      {
        city: 'Vancouver, Canada',
        hours: momentVancouver.format('H'),
        minutes: momentVancouver.format('mm'),
        seconds: momentVancouver.format('ss'),
        ampm: momentVancouver.format('A'),
      },
      {
        city: 'Singapore',
        hours: momentSingapore.format('H'),
        minutes: momentSingapore.format('mm'),
        seconds: momentSingapore.format('ss'),
        ampm: momentSingapore.format('A'),
      },
    ];
    setTimeZone(WORLDTIMES);
    //console.log(timeZone);
  };
  return (
    <ContactsTimeZoneContainer>
      <TimeZoneInnerWrapper>
        {timeZone.map((time, index) => (
          <AnalogClock key={index} time={time} index={index} />
        ))}
      </TimeZoneInnerWrapper>
    </ContactsTimeZoneContainer>
  );
};

export default ContactsTimeZone;
