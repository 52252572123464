import styled from 'styled-components';

export const HirePositionModalContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  background-color: #fff;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  /* padding-left: 83px;
  padding-right: 83px;
  padding-bottom: 191px; */
  box-sizing: content-box;
  @media ${({ theme }) => theme.mobile} {
    padding-top: 0;
  }
`;

export const CloseButtonWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-end;
`;

export const CloseButton = styled.div`
  &:after {
    font-weight: 300;
    font-size: 50px;
    color: #000;
    opacity: 0.7;
    content: '\\d7'; /* This will render the 'X' */
  }
`;

export const ModalHeader = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeaderTeam = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: ${({ theme }) => theme.notoSansKr};
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  color: #1c1c1c;
`;

export const HeaderPositionLocationWrapper = styled.div`
  margin-top: 12px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Position = styled.div`
  font-family: ${({ theme }) => theme.notoSansLight};
  font-size: 14px;
  line-height: 19px;
  color: #1c1c1c;
`;

export const Location = styled.div`
  font-family: ${({ theme }) => theme.notoSansLight};
  font-size: 14px;
  line-height: 19px;
  color: #b847d0;
`;

export const HeaderDivLine = styled.div`
  margin-top: 11px;
  width: 100%;
  height: 1px;
  background-color: #dddddd;
`;

export const ContentsBody = styled.div`
  margin-top: 12px;
  width: 100%;
  font-family: ${({ theme }) => theme.notoSansKr};
  font-weight: 300;
  line-height: 28px;
  color: #858585;
`;
