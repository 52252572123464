import styled from 'styled-components';
import { CircleContainer } from '../recruitment-circle/recuitment-circle.styles';

export const RecruitmentDiagramContainer = styled.div`
  position: relative;
  margin-top: 8.1875rem;
  display: flex;
  max-width: 100%;
  width: 65rem;
  align-items: center;
  justify-content: center;
  ${CircleContainer}:nth-child(4) {
    margin-right: 16px;
  }
  @media ${({ theme }) => theme.mobile} {
    display: none;
  }
`;

export const HorizontalLine = styled.span`
  z-index: -1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 1px;
  width: 100%;
  max-width: 1000px;
  background-color: #ddd;
`;

export const OrangeVerticalLine = styled.span`
  position: absolute;
  left: calc(50% - 180px);
  bottom: -43px;
  height: 90px;
  width: 2px;
  background-color: #ff8300;

  @media ${({ theme }) => theme.tablet} {
    left: calc(50% - 140px);
    height: 75px;
  }
`;
