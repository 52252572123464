import { createSelector } from 'reselect';

const selectGnb = (state) => state.gnb;

export const selectMobileMenu = createSelector(
  [selectGnb],
  (gnb) => gnb.mobileMenu
);

export const selectMenuList = createSelector(
  [selectGnb],
  (gnb) => gnb.menuList
);
