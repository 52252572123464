import { createSelector } from 'reselect';

const selectLocales = (state) => state.locales;

export const selectLocaleMenu = createSelector(
  [selectLocales],
  (locales) => locales.localeMenu
);

export const selectActiveLocale = createSelector(
  [selectLocales],
  (locales) => locales.activeLocale
);
