import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import NewsCard from '../news-card/news-card.component';

import { selectNewsItems } from '../../redux/posts/posts.selector';

import { NewsContentContainer } from './news-content.styles';

const NewsContent = ({ newsItems }) => {
  return (
    <NewsContentContainer>
      {newsItems.map(({ id, ...otherCollectionProps }) => (
        <NewsCard key={id} {...otherCollectionProps} size={'large'} />
      ))}
    </NewsContentContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  newsItems: selectNewsItems,
});

export default connect(mapStateToProps)(NewsContent);
