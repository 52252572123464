import React from 'react';

import {
  LifeOfVertigoBoxWrapper,
  BoxIcon,
  BoxTitle,
  BoxListWrapper,
  BoxList,
} from './lifeOfVertigoBox.styles';

const LifeOfVertigoBox = ({ icon, title, list, size }) => {
  return (
    <LifeOfVertigoBoxWrapper size={size}>
      <BoxIcon icon={icon} />
      <BoxTitle>{title}</BoxTitle>
      <BoxListWrapper>
        {list.map((desc, index) => (
          <BoxList key={index}>{desc}</BoxList>
        ))}
      </BoxListWrapper>
    </LifeOfVertigoBoxWrapper>
  );
};

export default LifeOfVertigoBox;
