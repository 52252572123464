import styled from 'styled-components';

export const CareersPageContainer = styled.div``;

export const CareersPageInnerContainer = styled.div`
  margin-top: 8.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  #hiringSection {
    width: 100%;
  }
`;

export const PageSectionTitleWrapper = styled.div`
  width: 100%;
  max-width: 63.3125rem;
  @media ${({ theme }) => theme.tablet} {
    padding: ${({ padding }) => (padding ? '0 60px' : 0)};
  }
  @media ${({ theme }) => theme.tablet} {
    padding: ${({ padding }) => (padding ? '0 40px' : 0)};
  }
`;

export const GoalWrapper = styled.div`
  position: relative;
  margin-top: 13px;
  width: 100%;
  text-align: center;
  height: 16.25rem;
  background-color: #f6f6f6;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const QuoteStart = styled.img`
  position: absolute;
  left: 0;
  right: ${({ locale }) => (locale === 'ko' ? '463px' : '563px')};
  top: -140px;
  bottom: 0;
  margin: auto;

  @media ${({ theme }) => theme.tablet} {
    right: ${({ locale }) => (locale === 'ko' ? '435px' : '510px')};
    top: -100px;
  }
  @media ${({ theme }) => theme.mobile} {
    left: 0;
    right: 0;
    top: -160px;
  }
`;

export const QuoteEnd = styled.img`
  position: absolute;
  left: ${({ locale }) => (locale === 'ko' ? '446px' : '375px')};
  right: 0;
  top: 0;
  bottom: -95px;
  margin: auto;

  @media ${({ theme }) => theme.tablet} {
    left: ${({ locale }) => (locale === 'ko' ? '485px' : '335px')};
  }
  @media ${({ theme }) => theme.mobile} {
    left: 0;
    right: 0;
    bottom: -160px;
  }
`;

export const GoalDesc = styled.div`
  font-family: ${({ theme }) => theme.notoSansKr};
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 50px;
  color: #1c1c1c;

  @media ${({ theme }) => theme.tablet} {
    font-size: 22px;
    line-height: 39px;
  }
  @media ${({ theme }) => theme.mobile} {
    font-size: 18px;
    line-height: 32px;
  }
`;

export const PaddingWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 60px;
`;

export const CareersNumberBoxContainer = styled.div`
  width: 100%;
  max-width: 1013px;
`;

export const LifeOfVertigoContainer = styled.div`
  margin-top: 11.625rem;
  width: 100%;
  max-width: 63.3125rem;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media ${({ theme }) => theme.mobile} {
    margin-top: 62px;
  }
`;

export const LifeOfVertigoBoxContainer = styled.div`
  margin-top: 34px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${({ theme }) => theme.tablet} {
    flex-direction: column;
  }
`;

export const RecruitmentContainer = styled.div`
  margin-top: 12.5rem;
  width: 100%;
  max-width: 65rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  @media ${({ theme }) => theme.mobile} {
    margin-top: 87px;
  }
`;

export const RecruitmentStepsContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const MobileStepsTitleWrapper = styled.div`
  width: 100%;
  display: none;
  justify-content: flex-start;
  @media ${({ theme }) => theme.mobile} {
    margin-top: 28px;
    display: flex;
  }
`;

export const MobileStepsTitleNumber = styled.div`
  font-size: 18px;
  line-height: 27px;
  font-weight: 500;
  font-family: ${({ theme }) => theme.notoSansKr};
`;
export const MobileStepsTile = styled.div`
  color: #1c1c1c;
  font-family: ${({ theme }) => theme.notoSansKr};
  font-size: 18px;
  font-weight: 300;
  line-height: 27px;
`;

export const ProcessInfoContainer = styled.div`
  margin-top: 252px;
  width: 100%;
  max-width: 22.75rem;
  font-family: ${({ theme }) => theme.notoSansKr};
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.625rem;
  color: #858585;

  @media ${({ theme }) => theme.tablet} {
    font-size: 14px;
    line-height: 24px;
  }
  @media ${({ theme }) => theme.mobile} {
    margin-top: 50px;
  }
`;

export const HiringPositionsContainer = styled.div`
  margin-top: 8.5625rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const HirePositionTitleWrapper = styled.div`
  width: 100%;
  max-width: 65rem;
  display: flex;
  justify-content: flex-start;
`;
