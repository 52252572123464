import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// selector
import {
  selectDevelopGames,
  selectPublishGames,
} from '../../redux/games/games.selector';

import { selectSortedGameItems } from '../../redux/games/games.selector';

// components
import MainSubSection from '../../components/main-sub-section/MainSubSection.component';
import MainGameSection from '../../components/main-game-section/MainGameSection.component';
import MainNewsSection from '../../components/main-news-section/main-news-section.component';
import ModalWindow from '../../components/modal-window/modal-window.component';
import GameDetails from '../../components/game-details/game-details.component';

// image
import MainImage from '../../components/main-image/main-image.component';

// styles
import { MainPageContainer, MainPageContentContainer } from './MainPage.styles';

class MainPage extends Component {
  render() {
    const { developGames, publishGames, gameItems } = this.props;

    return (
      <MainPageContainer>
        <MainImage />
        <MainSubSection />
        <MainPageContentContainer>
          <MainGameSection
            section='games'
            games={developGames}
            subtitle='main_section_games_title'
            title='main_section_games_main_title'
            desc='main_section_games_main_desc'
          />
          <MainGameSection
            section='publish'
            games={publishGames}
            subtitle='main_section_publish_title'
            title='main_section_publish_main_title'
            desc='main_section_publish_main_desc'
          />
          <MainNewsSection
            section='news'
            subtitle='main_section_news_title'
            title='main_section_news_main_title'
            desc='main_section_news_main_desc'
          />
        </MainPageContentContainer>
        <ModalWindow>
          <GameDetails gameItems={gameItems} />
        </ModalWindow>
      </MainPageContainer>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  developGames: selectDevelopGames,
  publishGames: selectPublishGames,
  gameItems: selectSortedGameItems,
});

export default connect(mapStateToProps)(MainPage);
