import styled from 'styled-components';

export const HireAccordionMenuContainer = styled.div`
  margin-top: 40px;
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media ${({ theme }) => theme.mobile} {
    margin-top: 0;
  }
`;
