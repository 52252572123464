import ModalActionTypes from './modal.types';
const INITIAL_STATE = {
  isModalOpen: false,
  itemId: null,
};

const modalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ModalActionTypes.OPEN_MODAL:
      return {
        ...state,
        isModalOpen: true,
        itemId: action.payload,
      };
    case ModalActionTypes.CLOSE_MODAL:
      return {
        ...state,
        isModalOpen: false,
        itemId: null,
      };
    default:
      return state;
  }
};

export default modalReducer;
