import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { selectIsPostFetching } from '../../redux/posts/posts.selector';

import WithSpinner from '../with-spinner/with-spinner.component';
import HirePosition from './hire-position.component';

const mapStateToProps = createStructuredSelector({
  isLoading: selectIsPostFetching,
});

const HirePositionContainer = compose(
  connect(mapStateToProps),
  WithSpinner
)(HirePosition);

export default HirePositionContainer;
