import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  SectionTitleContainer,
  SectionSubTitle,
  SectionTitle,
  SectionDesc,
} from './MainPageSectionTitle.styles';

const MainPageSectionTitle = ({ subtitle, title, desc, section }) => {
  return (
    <SectionTitleContainer>
      <SectionSubTitle>
        <FormattedMessage
          id={subtitle}
          values={{
            b: (...chunks) => <b>{chunks}</b>,
            span: (...chunks) => <span>{chunks}</span>,
          }}
        />
      </SectionSubTitle>
      <SectionTitle>
        <FormattedMessage id={title} />
      </SectionTitle>
      <SectionDesc section={section}>
        <FormattedMessage
          id={desc}
          values={{
            linebreak: <br />,
            span: (...chunks) => <span>{chunks}</span>,
          }}
        />
      </SectionDesc>
    </SectionTitleContainer>
  );
};

export default MainPageSectionTitle;
