import styled from 'styled-components';

export const HireNoPositionContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  font-family: ${(props) => props.theme.notoSansKr};
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  color: #000;
  @media ${({ theme }) => theme.tablet} {
    font-size: 14px;
  }
`;
