import styled from 'styled-components';

export const PageTopImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 40.625rem;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(${({ bgImg }) => bgImg});
  @media ${({ theme }) => theme.mobile} {
    height: 350px;
  }
`;

export const ImageOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
`;

export const PageTopTitle = styled.div`
  z-index: 2;
  text-align: center;
  width: 100%;
  max-width: 50rem;
  font-size: 3.125rem;
  line-height: 4.25rem;
  color: #fff;
  font-family: ${({ theme }) => theme.notoSansBold};

  @media ${({ theme }) => theme.tablet} {
    max-width: 600px;
    font-size: 40px;
    line-height: 55px;
  }
  @media ${({ theme }) => theme.mobile} {
    max-width: 280px;
    font-size: 25px;
    line-height: 34px;
  }
`;

export const PageTopSubtitle = styled.div`
  z-index: 2;
  margin-top: 3.75rem;
  width: 100%;
  text-align: center;
  font-family: ${({ theme }) => theme.notoSansBold};
  font-size: 1rem;
  line-height: 1.375rem;
  color: #ff8300;
  @media ${({ theme }) => theme.mobile} {
    margin-top: 37px;
    font-size: 8px;
  }
`;
