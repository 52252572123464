import React from 'react';

import papayaLogo from '../../assets/images/acsy/logo_papayaplay.png';
import rightArrow from '../../assets/images/acsy/ic_arrow_mid_wh_next.png';

import {
  ButtonContainer,
  PapayaLogoImg,
  ButtonText,
} from './papaya-play-button.styles';

const PapayaPlayButton = () => {
  return (
    <ButtonContainer
      href='https://www.papayaplay.com/portal.do'
      target='_blank'
    >
      <PapayaLogoImg src={papayaLogo} />
      <ButtonText>Website</ButtonText>
      <img src={rightArrow} alt='arrow' />
    </ButtonContainer>
  );
};

export default PapayaPlayButton;
