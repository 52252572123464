import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { openModal } from '../../redux/modal/modal.actions';

import {
  GameCardContainer,
  GameCardBackgroundImage,
  GameCardNoticeTag,
  CancelIcon,
  NoticeText,
  GameCardContentContainer,
  GameCardContentTitle,
  GameCardContentSubtitle,
  ViewMoreButton,
} from './game-card.styles';

const GameCard = ({ gameItem, index, section, openModal }) => {
  const { bgImage, id } = gameItem;
  const intl = useIntl();
  const cardContents = intl.messages[`main_section_${section}_games`];
  return (
    <GameCardContainer onClick={() => openModal(id)}>
      <GameCardBackgroundImage bgImg={bgImage} />
      {cardContents[index].notice ? (
        <GameCardNoticeTag>
          <CancelIcon />
          <NoticeText>{cardContents[index].notice}</NoticeText>
        </GameCardNoticeTag>
      ) : null}
      <GameCardContentContainer>
        <GameCardContentTitle>{cardContents[index].title}</GameCardContentTitle>
        <GameCardContentSubtitle>
          {cardContents[index].subtitle}
        </GameCardContentSubtitle>
        <ViewMoreButton>View More</ViewMoreButton>
      </GameCardContentContainer>
    </GameCardContainer>
  );
};

const mapDispatchToProps = (dispatch) => ({
  openModal: (index) => dispatch(openModal(index)),
});

export default connect(null, mapDispatchToProps)(GameCard);
