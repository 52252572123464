import styled from 'styled-components';

export const HistoryElementContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-content: center;

  // when the browser size 900px
  @media ${({ theme }) => theme.tablet} {
    max-width: 500px;
  }
`;

export const HistoryElementHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const HistoryTip = styled.span`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  color: #bbb;
  margin-right: 0.625rem;
`;

export const HistoryYear = styled.div`
  font-family: ${(props) => props.theme.notoSansKr};
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  color: #bbb;

  @media ${({ theme }) => theme.tablet} {
    font-size: 14px;
  }
`;

export const HistoryContainer = styled.div`
  margin-left: 39px;
  width: 50rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border-radius: 20px;
  background-color: #f6f6f6;
  @media ${({ theme }) => theme.mobile} {
    margin-left: 20px;
  }
`;

export const HistoryContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media ${({ theme }) => theme.tablet} {
    align-items: flex-start;
  }
  @media ${({ theme }) => theme.mobile} {
    flex-direction: column;
  }
`;

export const HistoryMonth = styled.div`
  margin-left: 30px;
  font-family: ${(props) => props.theme.notoSansKr};
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.875rem;
  color: #858585;

  @media ${({ theme }) => theme.tablet} {
    font-size: 14px;
  }
  @media ${({ theme }) => theme.mobile} {
    margin-left: 20px;
  }
`;

export const HistoryTitle = styled.div`
  margin-left: 13px;
  font-family: ${(props) => props.theme.notoSansKr};
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.875rem;
  color: #1c1c1c;

  @media ${({ theme }) => theme.tablet} {
    font-size: 14px;
    line-height: 27px;
  }
  @media ${({ theme }) => theme.mobile} {
    margin-left: 20px;
  }
`;
