import React from 'react';
import { connect } from 'react-redux';
import parse from 'html-react-parser';

import { selectCurrentPageTopContent } from '../../redux/pageTopContents/pageTopContents.selector';

import {
  PageTopImageContainer,
  ImageOverlay,
  PageTopTitle,
  PageTopSubtitle,
} from './page-top-image.styles';

const PageTopImage = ({ pageTopContent }) => {
  const { backgroundImage, title } = pageTopContent;
  return (
    <PageTopImageContainer bgImg={backgroundImage}>
      <ImageOverlay />
      <PageTopTitle>{parse(title)}</PageTopTitle>
      <PageTopSubtitle>VERTIGO GAMES</PageTopSubtitle>
    </PageTopImageContainer>
  );
};

const mapStateToProps = (state, ownProps) => ({
  pageTopContent: selectCurrentPageTopContent(ownProps.path)(state),
});

export default connect(mapStateToProps)(PageTopImage);
