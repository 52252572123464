import React from 'react';
import { useIntl } from 'react-intl';

import {
  NumberBoxContainer,
  NumberBox,
  NumberBoxTopWrapper,
  NumberText,
  NumberTitle,
  NumberDesc,
} from './careers-number-box.styles';

const CarrersNumberBox = () => {
  const intl = useIntl();
  const messages = intl.messages['careers_number_box'];
  return (
    <NumberBoxContainer>
      {messages.map(({ title, desc }, index) => (
        <NumberBox key={index}>
          <NumberBoxTopWrapper>
            <NumberText>{index + 1}</NumberText>
            <NumberTitle>{title}</NumberTitle>
          </NumberBoxTopWrapper>
          <NumberDesc>{desc}</NumberDesc>
        </NumberBox>
      ))}
    </NumberBoxContainer>
  );
};

export default CarrersNumberBox;
