import styled, { css } from 'styled-components';

const FacebookIcon = css`
    border: 1px solid #4267b2;
    > svg {
        filter: invert(38%) sepia(7%) saturate(6071%) hue-rotate(184deg) brightness(94%) contrast(80%);
    }
`;

const YoutubeIcon = css`
    border: 1px solid #ff0000;
    > svg {
        filter: invert(22%) sepia(98%) saturate(7492%) hue-rotate(2deg) brightness(104%) contrast(118%);
    }
`;

const TwitterIcon = css`
    border: 1px solid #1da1f2;
    > svg {
        filter: invert(49%) sepia(26%) saturate(3746%) hue-rotate(178deg) brightness(102%) contrast(90%);
    }
`;

const DiscordIcon = css`
    border: 1px solid #7289da;
    > svg {
        filter: invert(51%) sepia(80%) saturate(788%) hue-rotate(201deg) brightness(92%) contrast(84%);
    }
`;

const FacebookGroupIcon = css`
    border: 1px solid #4267b2;
    > svg {
        filter: invert(38%) sepia(7%) saturate(6071%) hue-rotate(184deg) brightness(94%) contrast(80%);
    }
`;

const InstagramIcon = css`
    border: 1px solid #b2429f;
    > svg {
        filter: invert(22%) sepia(98%) saturate(7492%) hue-rotate(2deg) brightness(104%) contrast(118%)
            hue-rotate(308deg) saturate(91%);
    }
`;

const TwitchIcon = css`
    border: 1px solid #6441a5;
    > svg {
        filter: invert(25%) sepia(44%) saturate(2383%) hue-rotate(240deg) brightness(93%) contrast(84%);
    }
`;

const getSnsHoverStyle = ({ sns }) => {
    if (sns === 'facebook') {
        return FacebookIcon;
    }
    if (sns === 'youtube') {
        return YoutubeIcon;
    }
    if (sns === 'twitter') {
        return TwitterIcon;
    }
    if (sns === 'discord') {
        return DiscordIcon;
    }
    if (sns === 'facebookGroup') {
        return FacebookGroupIcon;
    }
    if (sns === 'instagram') {
        return InstagramIcon;
    }
    if (sns === 'twitch') {
        return TwitchIcon;
    }

    return;
};

export const SnsIconWrapper = styled.a`
    margin: 0 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #909090;
    display: flex;
    justify-content: center;
    align-items: center;
    > svg {
        width: 20px;
        height: 20px;
        filter: invert(60%) sepia(0%) saturate(84%) hue-rotate(170deg) brightness(95%) contrast(92%);
    }
    &:hover {
        ${getSnsHoverStyle}
    }
`;
