import styled from 'styled-components';
import mapImage from '../../assets/images/acsy/map.png';
import {
  ContactsMapPinContainer,
  ContactMapDetailWrapper,
} from '../contacts-map-pin/contacts-map-pin.styles';

export const ContactsMapContainer = styled.div`
  margin-top: 245px;
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${({ theme }) => theme.tablet} {
    margin-top: 170px;
  }
  @media ${({ theme }) => theme.tablet} {
    padding: 0 40px;
    margin-top: 112px;
  }
`;

export const ContactsMapDesc = styled.div`
  margin-top: 80px;
  font-family: ${(props) => props.theme.notoSansKr};
  font-size: 1rem;
  font-weight: 300;
  line-height: 24px;
  color: #1c1c1c;
  @media ${({ theme }) => theme.tablet} {
    margin-top: 41px;
  }
  @media ${({ theme }) => theme.mobile} {
    text-align: center;
  }
`;

export const ContactsMapWrapper = styled.div`
  margin-top: 78px;
  position: relative;
  width: 100%;
  max-width: 835px;
  height: 412px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${mapImage});
  @media ${({ theme }) => theme.mobile} {
    display: none;
  }
`;

export const ContactsLocationContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // korea
  ${ContactsMapPinContainer}:nth-child(1) {
    top: 125px;
    right: 115px;
    &:hover {
      ${ContactMapDetailWrapper} {
        display: flex;
      }
    }
    ${ContactMapDetailWrapper} {
      display: none;
      border-radius: 20px 20px 0 20px;
      top: -240px;
      right: 50px;
    }
  }
  // vancouver
  ${ContactsMapPinContainer}:nth-child(2) {
    top: 105px;
    left: 100px;
    &:hover {
      ${ContactMapDetailWrapper} {
        display: flex;
      }
    }
    ${ContactMapDetailWrapper} {
      display: none;
      top: -180px;
      left: 50px;
      border-radius: 20px 20px 20px 0;
    }
  }
  // singapore
  ${ContactsMapPinContainer}:nth-child(3) {
    top: 230px;
    right: 170px;
    &:hover {
      ${ContactMapDetailWrapper} {
        display: flex;
      }
    }
    ${ContactMapDetailWrapper} {
      display: none;
      top: -180px;
      left: 50px;
      border-radius: 20px 20px 20px 0;
    }
  }
`;
