import React from 'react';
import loading from '../../assets/images/acsy/Rolling-1s-200px.svg';

import { WithSpinnerContainer } from './with-spinner.styles';

//HOC
const WithSpinner =
  (WrappedComponent) =>
  ({ isLoading, ...otherProps }) => {
    return isLoading ? (
      <WithSpinnerContainer>
        <img src={loading} alt='Loading...' />
      </WithSpinnerContainer>
    ) : (
      <WrappedComponent {...otherProps} />
    );
  };

export default WithSpinner;
