import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  enableOffice,
  handleMobileFilterMenu,
} from '../../redux/careersPage/careersPage.actions';

import {
  selectSeoulPositionPosts,
  selectVancouverPositionPosts,
  selectSingaporePositionPosts,
} from '../../redux/posts/posts.selector';

import {
  selectOfficeFilter,
  selectMobileFilterMenu,
} from '../../redux/careersPage/careersPage.selector';

import {
  MobileHirePositionFilterContainer,
  FilterActive,
  FilterList,
  FilterButton,
  ActiveOffice,
  ArrowMenu,
} from './mobile-hire-position-filter.styles';

import downArrow from '../../assets/images/acsy/ic_arrow_mid_wh_down.png';

const MobileHirePositionFilter = ({
  officeFilter,
  seoulPositionPosts,
  vancouverPositionPosts,
  singaporePositionPosts,
  enableFilterMenu,
  handleMobileFilterMenu,
  mobileFilterMenu,
}) => {
  const officeFilterData = officeFilter;
  officeFilterData[0].number = seoulPositionPosts.length;
  officeFilterData[1].number = vancouverPositionPosts.length;
  officeFilterData[2].number = singaporePositionPosts.length;

  return (
    <MobileHirePositionFilterContainer>
      {officeFilter.map(({ isActive, office, number }, index) =>
        isActive ? (
          <FilterActive key={index} onClick={() => handleMobileFilterMenu()}>
            <ActiveOffice>
              {office} ({number})
            </ActiveOffice>
            <ArrowMenu src={downArrow} alt='arrow' />
          </FilterActive>
        ) : null
      )}

      <FilterList mobileFilterMenu={mobileFilterMenu}>
        {officeFilter.map(({ isActive, office, number }, index) =>
          !isActive ? (
            <FilterButton key={index} onClick={() => enableFilterMenu(index)}>
              {office} ({number})
            </FilterButton>
          ) : null
        )}
      </FilterList>
    </MobileHirePositionFilterContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  seoulPositionPosts: selectSeoulPositionPosts,
  vancouverPositionPosts: selectVancouverPositionPosts,
  singaporePositionPosts: selectSingaporePositionPosts,
  officeFilter: selectOfficeFilter,
  mobileFilterMenu: selectMobileFilterMenu,
});

const mapDispatchToProps = (dispatch) => ({
  enableFilterMenu: (index) => dispatch(enableOffice(index)),
  handleMobileFilterMenu: () => dispatch(handleMobileFilterMenu()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileHirePositionFilter);
