import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  PageSectionTitleContainer,
  PageSectionLine,
  PageSectionText,
} from './page-section-title.styles';

const PageSectionTitle = ({ title, color, size, linePosition }) => {
  return (
    <PageSectionTitleContainer linePosition={linePosition}>
      <PageSectionLine color={color} linePosition={linePosition} />
      <PageSectionText size={size}>
        <FormattedMessage
          id={title}
          values={{
            b: (...chunks) => <b>{chunks}</b>,
            span: (...chunks) => <span>{chunks}</span>,
          }}
        />
      </PageSectionText>
    </PageSectionTitleContainer>
  );
};

export default PageSectionTitle;
