import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { setLocale } from '../../redux/locales/locales.actions';

import {
  selectLocaleMenu,
  selectActiveLocale,
} from '../../redux/locales/locales.selector';

import {
  LocaleMenuContainer,
  LocaleWrapper,
  LocaleText,
  CheckMark,
} from './locale-menu.styles';

const LocaleMenu = ({ localeMenu, activeLocale, setLocale }) => {
  return (
    <LocaleMenuContainer>
      {localeMenu.map((locale) => (
        <LocaleWrapper key={locale.code} onClick={() => setLocale(locale.code)}>
          <CheckMark active={activeLocale.includes(locale.code)} />
          <LocaleText active={activeLocale.includes(locale.code)}>
            {locale.lang}
          </LocaleText>
        </LocaleWrapper>
      ))}
    </LocaleMenuContainer>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setLocale: (locale) => dispatch(setLocale(locale)),
});

const mapStateToProps = createStructuredSelector({
  localeMenu: selectLocaleMenu,
  activeLocale: selectActiveLocale,
});

export default connect(mapStateToProps, mapDispatchToProps)(LocaleMenu);
