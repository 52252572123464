const size = {
  mobile: '600px',
  tablet: '900px',
  laptop: '1200px',
  desktop: '1800px',
};
export const theme = {
  // fonts
  notoSansKr: 'Noto Sans KR, sans-serif',
  notoSansThin: 'Noto Sans Thin', // weight: 100
  notoSansExtraLight: 'Noto Sans Extra Light', // weight: 200
  notoSansLight: 'Noto Sans Light', // weight: 300
  notoSansRegular: 'Noto Sans Regular', // weight: 400
  notoSansMedium: 'Noto Sans Medium', // weight: 500
  notoSansBold: 'Noto Sans Bold', // weight: 600

  // breakpoints
  mobile: `(max-width: ${size.mobile})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  desktop: `(min-width: ${size.desktop})`,
};
