export const Base = `
html,body {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

* {
  box-sizing: border-box;
}

a,
a:visited,
a:link,
y a:hover {
  text-decoration: none;
}

div {
  outline: none;
}

button {
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  background: none;
  border: none;
}

button:focus {
  outline: 0;
}

h1,h2,h3,h4,h5,h6 {
  margin: 0;
}

p {
  margin: 0;
}

li {
  list-style: none;
}

#root {
  position: relative;
}
`;
