import React from 'react';
import { FormattedMessage } from 'react-intl';

import { HireNoPositionContainer } from './hire-no-position.styles';

const HireNoPosition = () => {
  return (
    <HireNoPositionContainer>
      <FormattedMessage id='careers_section_4_no_hire' />
    </HireNoPositionContainer>
  );
};

export default HireNoPosition;
