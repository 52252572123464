import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useLocation } from 'react-router-dom';

import GlobeMenu from '../../globe-menu/globe-menu.component';

import { handleMobileGnbMenu } from '../../../redux/gnb/gnb.actions';

import { selectMenuList } from '../../../redux/gnb/gnb.selectors';

import {
  GnbElementContainer,
  LinkElement,
  LinkElementWrapper,
  ActiveMenuBar,
} from './gnb-element.styles';

const GnbElement = ({ footer, menuList, handleMobileGnbMenu }) => {
  const { pathname } = useLocation();
  const handleLink = () => {
    if (footer) return;
    return handleMobileGnbMenu();
  };
  return (
    <GnbElementContainer footer={footer}>
      {menuList.map(({ link, name }) => (
        <LinkElementWrapper
          key={link}
          onClick={() => handleLink()}
          footer={footer}
        >
          <LinkElement
            to={link}
            footer={footer}
            location={pathname}
            link={link}
          >
            {name}
          </LinkElement>
          <ActiveMenuBar footer={footer} location={pathname} link={link} />
        </LinkElementWrapper>
      ))}
      <GlobeMenu footer={footer}></GlobeMenu>
    </GnbElementContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  menuList: selectMenuList,
});

const mapDispatchToProps = (dispatch) => ({
  handleMobileGnbMenu: () => dispatch(handleMobileGnbMenu()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GnbElement);
