import styled from 'styled-components';

export const ContactsTimeZoneContainer = styled.div`
  margin-top: 90px;
  height: 260px;
  width: 100%;
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${({ theme }) => theme.mobile} {
    margin-top: 52px;
    background-color: #fff;
    height: auto;
  }
`;

export const TimeZoneInnerWrapper = styled.div`
  width: 100%;
  max-width: 770px;
  flex-direction: row;
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media ${({ theme }) => theme.mobile} {
    flex-direction: column;
  }
`;
