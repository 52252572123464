import styled from 'styled-components';

export const GameCardBackgroundImage = styled.div`
  border-radius: 1.25rem;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  right: 0;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  background-image: ${({ bgImg }) => `url(${bgImg})`};
`;

export const GameCardContainer = styled.div`
  position: relative;
  cursor: pointer;
  margin-top: 1.875rem;
  margin-left: 0.3125rem;
  box-shadow: 0.4375rem 0.4375rem 0.9375rem 0rem rgba(0, 0, 0, 0.3);
  width: 22.5rem;
  height: 30.3125rem;
  border-radius: 1.25rem;
  flex-direction: column;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
  &:hover {
    transform: scale(1.05);
    box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.3);
    // referring GameCardBackgroundImage compoment
    ${GameCardBackgroundImage} {
      transform: scale(1.1);
    }
  }
  @media ${({ theme }) => theme.tablet} {
    height: 475px;
  }
  @media ${({ theme }) => theme.mobile} {
    margin-top: 0;
    width: 200px;
    height: 264px;
    margin-right: 15px;
  }
`;

export const GameCardNoticeTag = styled.div`
  height: 2.1875rem;
  width: 7.8125rem;
  margin-top: 1.8125rem;
  margin-left: 13.0625rem;
  padding: 0 5px;
  z-index: 5;
  border: 0.5px solid rgba(255, 255, 255, 0.7);
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${({ theme }) => theme.mobile} {
    margin-top: 0;
    margin-left: auto;
  }
`;

export const NoticeText = styled.h6`
  opacity: 0.7;
  margin-left: 13px;
  font-family: ${({ theme }) => theme.notoSansThin};
  font-size: 0.8rem;
  line-height: 0.9rem;
  color: #fff;
  font-weight: 100;
`;

export const CancelIcon = styled.div`
  margin-bottom: 4px;
  &:after {
    font-weight: 100;
    font-size: 30px;
    color: #fff;
    opacity: 0.7;
    content: '\\d7'; /* This will render the 'X' */
  }
`;

export const GameCardContentContainer = styled.div`
  position: absolute;
  top: 18.625rem;
  left: 2.5rem;
  margin: 0 auto;
  width: 100%;
  max-width: 20rem;
  z-index: 5;
  @media ${({ theme }) => theme.mobile} {
    top: 143px;
    left: 16px;
  }
`;

export const GameCardContentTitle = styled.h3`
  font-size: 2.125rem;
  font-weight: 500;
  line-height: 2.5rem;
  color: #fff;
  font-family: ${({ theme }) => theme.notoSansKr};
  @media ${({ theme }) => theme.mobile} {
    font-size: 15px;
    line-height: 29px;
  }
`;

export const GameCardContentSubtitle = styled.h4`
  margin-bottom: 1.0625rem;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5rem;
  color: #fff;
  font-family: ${({ theme }) => theme.notoSansKr};
  @media ${({ theme }) => theme.mobile} {
    font-size: 13px;
  }
`;

export const ViewMoreButton = styled.button`
  cursor: pointer;
  height: 1.875rem;
  width: 6.625rem;
  border: 1px solid #ffffff;
  border-radius: 5px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.1875rem;
  color: #ffffff;
  font-weight: ${(theme) => theme.notoSansLight};
  @media ${({ theme }) => theme.mobile} {
    width: 5rem;
    font-size: 10px;
  }
`;
