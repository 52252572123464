import styled from 'styled-components';
import { Link } from 'react-router-dom';

import vertigoLogo from '../../../assets/images/bg/vertigogames_w.png';

export const GnbFullContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8.27%;
  background-color: ${({ isReachedTop, pathname }) =>
    isReachedTop ? 'transparent' : '#000'};
  opacity: ${({ isReachedTop, pageYOffset, pathname }) =>
    isReachedTop ? 1 : Math.min(pageYOffset / 600, 1)};

  // when the browser size 900px
  @media ${({ theme }) => theme.tablet} {
    display: none;
  }
`;

export const VertigoLogoHome = styled(Link)`
  width: 156px;
  height: 32px;
  background-image: url(${vertigoLogo});
`;
