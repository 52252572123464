import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { handleMobileGnbMenu } from '../../../../redux/gnb/gnb.actions';

import { selectMobileMenu } from '../../../../redux/gnb/gnb.selectors';

import {
  HamburgetButtonContainer,
  ButtonLine,
} from './gnb-hamburger-button.styles';

const GnbHamburgerButton = ({ mobileMenu, handleMobileGnbMenu }) => {
  return (
    <HamburgetButtonContainer onClick={() => handleMobileGnbMenu()}>
      <ButtonLine mobileMenu={mobileMenu} />
      <ButtonLine mobileMenu={mobileMenu} />
      <ButtonLine mobileMenu={mobileMenu} />
    </HamburgetButtonContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  mobileMenu: selectMobileMenu,
});

const mapDispatchToProps = (dispatch) => ({
  handleMobileGnbMenu: () => dispatch(handleMobileGnbMenu()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GnbHamburgerButton);
