import React from 'react';

import MainPageSectionTitle from '../main-page-section-title/MainPageSectionTitle.component';
import GameSlickSlider from '../game-slick-slider/game-slick-slider.component';
import PapayaPlayButton from '../papaya-play-button/papaya-play-button.component';

import { MainGameSectionContainer } from './MainGameSection.styles';

const MainGameSection = ({ games, subtitle, title, desc, section }) => {
  return (
    <MainGameSectionContainer>
      <MainPageSectionTitle
        subtitle={subtitle}
        title={title}
        desc={desc}
        section={section}
      />
      <GameSlickSlider slideItems={games} section={section} />
      {section === 'publish' ? <PapayaPlayButton /> : null}
    </MainGameSectionContainer>
  );
};

export default MainGameSection;
