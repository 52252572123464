import styled from 'styled-components';

export const HistoryTableContainer = styled.div`
  margin-top: 122px;
  position: relative;
  width: 100%;
  max-width: 56rem;
  > div:nth-child(2) {
    margin-bottom: 47px;
  }
  > div:nth-child(3) {
    margin-bottom: 47px;
  }
  > div:nth-child(4) {
    margin-bottom: 47px;
  }
  > div:nth-child(5) {
    margin-bottom: 47px;
  }
  > div:nth-child(6) {
    margin-bottom: 49px;
  }
  > div:nth-child(7) {
    margin-bottom: 50px;
  }
  > div:nth-child(8) {
    margin-bottom: 49px;
  }
  > div:nth-child(9) {
    margin-bottom: 50px;
  }
  > div:nth-child(10) {
    margin-bottom: 92px;
  }
  > div:nth-child(11) {
    margin-bottom: 105px;
  }
  > div:nth-child(12) {
    margin-bottom: 90px;
  }
  > div:nth-child(13) {
    margin-bottom: 143px;
  }
  > div:nth-child(14) {
    margin-bottom: 60px;
  }
`;

export const HistoryLineVertical = styled.div`
  left: 0;
  top: 0;
  margin: 0 auto;
  position: absolute;
  // height: 1665px;
  height: 99%;
  width: 2px;
  background-color: #dddddd;
`;

export const HistoryRowWrapper = styled.div``;
