import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import HireAccordionMenu from '../hire-accorion-menu/hire-accordion-menu.component';
import HireNoPosition from '../hire-no-position/hire-no-position.compnent';

import {
  selectSeoulPositionPosts,
  selectVancouverPositionPosts,
  selectSingaporePositionPosts,
} from '../../redux/posts/posts.selector';

import { selectOfficeFilter } from '../../redux/careersPage/careersPage.selector';

import { HireAccordionMenuContainer } from './hire-accordion.styles';

const HireAccordion = ({
  officeFilter,
  seoulPositionPosts,
  vancouverPositionPosts,
  singaporePositionPosts,
}) => {
  return (
    <HireAccordionMenuContainer>
      {officeFilter[0].isActive ? (
        seoulPositionPosts.length > 0 ? (
          seoulPositionPosts.map((post) => (
            <HireAccordionMenu key={post.id} post={post}></HireAccordionMenu>
          ))
        ) : (
          <HireNoPosition />
        )
      ) : null}

      {officeFilter[1].isActive ? (
        vancouverPositionPosts.length > 0 ? (
          vancouverPositionPosts.map((post) => (
            <HireAccordionMenu key={post.id} post={post}></HireAccordionMenu>
          ))
        ) : (
          <HireNoPosition />
        )
      ) : null}

      {officeFilter[2].isActive ? (
        singaporePositionPosts.length > 0 ? (
          singaporePositionPosts.map((post) => (
            <HireAccordionMenu key={post.id} post={post}></HireAccordionMenu>
          ))
        ) : (
          <HireNoPosition />
        )
      ) : null}
    </HireAccordionMenuContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  seoulPositionPosts: selectSeoulPositionPosts,
  vancouverPositionPosts: selectVancouverPositionPosts,
  singaporePositionPosts: selectSingaporePositionPosts,
  officeFilter: selectOfficeFilter,
});

export default connect(mapStateToProps)(HireAccordion);
