import styled, { css } from 'styled-components';

const circleLargeStyle = css`
  height: 15.125rem;
  width: 15.125rem;
  border: 2px solid #ff8300;
  @media ${({ theme }) => theme.tablet} {
    width: 187px;
    height: 187px;
  }
`;

const circleMediumStyle = css`
  height: 13.75rem;
  width: 13.75rem;
  background-color: #dddddd;
  @media ${({ theme }) => theme.tablet} {
    width: 170px;
    height: 170px;
  }
`;

const circleSmallStyle = css`
  height: 8.4375rem;
  width: 8.4375rem;
  background-color: #f0f0f0;
  @media ${({ theme }) => theme.tablet} {
    width: 104px;
    height: 104px;
  }
`;

const getCircleSizeStyle = ({ size }) => {
  if (size === 'large') {
    return circleLargeStyle;
  }
  if (size === 'medium') {
    return circleMediumStyle;
  }
  return circleSmallStyle;
};

const circleSmallNumberStyle = css`
  max-width: 5rem;
`;

const circleMediumNumberStyle = css`
  max-width: 6.625rem;
`;

const circleLargeNumberStyle = css`
  max-width: 8.3125rem;
`;

const getCircleNumberStyle = ({ size }) => {
  if (size === 'large') {
    return circleLargeNumberStyle;
  }
  if (size === 'medium') {
    return circleMediumNumberStyle;
  }
  return circleSmallNumberStyle;
};

const circleSmallTitleStyle = css`
  max-width: 100px;
`;
const circleMediumTitleStyle = css`
  max-width: initial;
`;
const circleLargeTitleStyle = css`
  max-width: 133px;
`;

const getCircleTitleStyle = ({ size }) => {
  if (size === 'large') {
    return circleLargeTitleStyle;
  }
  if (size === 'medium') {
    return circleMediumTitleStyle;
  }
  return circleSmallTitleStyle;
};

const circleSmallWrapperStyle = css`
  max-width: 100px;
  @media ${({ theme }) => theme.tablet} {
    max-width: 80px;
  }
`;
const circleMediumWrapperStyle = css`
  max-width: 120px;
  @media ${({ theme }) => theme.tablet} {
    max-width: 87px;
  }
`;
const circleLargeWrapperStyle = css`
  max-width: 133px;
  @media ${({ theme }) => theme.tablet} {
    max-width: 100px;
  }
`;

const getCircleWrapperStyle = ({ size }) => {
  //console.log(size);
  if (size === 'large') {
    return circleLargeWrapperStyle;
  }
  if (size === 'medium') {
    return circleMediumWrapperStyle;
  }
  return circleSmallWrapperStyle;
};

export const CircleContainer = styled.div`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${getCircleSizeStyle}
`;

export const CircleWrapper = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  ${getCircleWrapperStyle};
`;

export const CircleNumber = styled.div`
  width: 100%;
  text-align: left;
  font-family: ${({ theme }) => theme.notoSansKr};
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 33px;
  color: #1c1c1c;
  ${getCircleNumberStyle}
  @media ${({ theme }) => theme.tablet} {
    font-size: 18px;
    line-height: 27px;
  }
`;

export const CircleTitle = styled.div`
  width: 100%;
  text-align: left;
  font-family: ${({ theme }) => theme.notoSansKr};
  font-size: 1.3125rem;
  font-weight: 300;
  line-height: 33px;
  color: #1c1c1c;
  ${getCircleTitleStyle}
  @media ${({ theme }) => theme.tablet} {
    font-size: 18px;
    line-height: 27px;
  }
`;
