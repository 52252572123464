import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import VisibilitySensor from 'react-visibility-sensor';
import * as Scroll from 'react-scroll';

import { fetchPostStart } from '../../redux/posts/posts.actions';

import { selectAllPostItems } from '../../redux/posts/posts.selector';
import { selectCircleData } from '../../redux/careersPage/careersPage.selector';

import {
  selectLifeOfVertigoImageTop,
  selectLifeOfVertigoImageBottom,
} from '../../redux/careersPage/careersPage.selector';

import PageTopImage from '../../components/page-top-image/page-top-image.component';
import PageSectionTitle from '../../components/page-section-title/page-section-title.component';
import CarrersNumberBox from '../../components/careers-number-box/careers-number-box.component';
import LifeOfVertigoList from '../../components/life-of-vertigo-list/lifeOfVertigoList.component';
import LifeOfVertigoBox from '../../components/life-of-vertigo-box/lifeOfVertigoBox.component';
import RecruitmentDiagram from '../../components/recuitment-diagram/recruitment-diagram.component';
import StepList from '../../components/step-list/step-list.component';
import HirePosition from '../../components/hire-position/hire-position.container';
import NavToHireSection from '../../components/nav-to-hire-section/nav-to-hire-section.component';
import ModalWindow from '../../components/modal-window/modal-window.component';
import HirePositionModal from '../../components/hire-position-modal/hire-position-modal.component';

import quoteStart from '../../assets/images/acsy/ic_t_mark_start.png';
import quoteEnd from '../../assets/images/acsy/ic_t_mark_fin.png';

import {
  CareersPageContainer,
  CareersPageInnerContainer,
  PageSectionTitleWrapper,
  GoalWrapper,
  QuoteStart,
  QuoteEnd,
  GoalDesc,
  LifeOfVertigoContainer,
  LifeOfVertigoBoxContainer,
  RecruitmentContainer,
  RecruitmentStepsContainer,
  ProcessInfoContainer,
  HiringPositionsContainer,
  HirePositionTitleWrapper,
  CareersNumberBoxContainer,
  PaddingWrapper,
  MobileStepsTitleWrapper,
  MobileStepsTitleNumber,
  MobileStepsTile,
} from './CareersPage.styles';

const CareersPage = ({
  fetchPostStart,
  postItems,
  match,
  lifeOfVertigoIconTop,
  lifeOfVertigoIconBottom,
  circleData,
}) => {
  useEffect(() => {
    if (postItems.length <= 0) {
      fetchPostStart();
    }
  });
  const intl = useIntl();
  const currentLocale = intl.locale;
  const lifeOfVertigoTop = intl.messages['lifebox_data_top'];
  const lifeOfVertigoBottom = intl.messages['lifebox_data_bottom'];
  const stepsMessageFirst = intl.messages['first_step_data'];
  const stepsMessageSecond = intl.messages['second_step_data'];
  const stepsMessageThird = intl.messages['third_step_data'];
  console.log(postItems);
  return (
    <CareersPageContainer>
      <PageTopImage path={match.path} />
      <CareersPageInnerContainer>
        <PageSectionTitleWrapper padding={true}>
          <PageSectionTitle title='careers_section_1_title' />
        </PageSectionTitleWrapper>
        <GoalWrapper>
          <QuoteStart src={quoteStart} alt='quote' locale={currentLocale} />
          <GoalDesc>
            <FormattedMessage
              id='careers_section_1_goal_desc'
              values={{
                linebreak: <br />,
                b: (...chunks) => <b>{chunks}</b>,
              }}
            />
          </GoalDesc>
          <QuoteEnd src={quoteEnd} alt='quote' locale={currentLocale} />
        </GoalWrapper>
        <PaddingWrapper>
          <CareersNumberBoxContainer>
            <CarrersNumberBox />
          </CareersNumberBoxContainer>
          <LifeOfVertigoContainer>
            <PageSectionTitleWrapper padding={false}>
              <PageSectionTitle title='careers_section_2_title' />
            </PageSectionTitleWrapper>
            <LifeOfVertigoList
              number='01'
              title='careers_vglife_title_1'
              desc='careers_vglife_desc_1'
            />
            <LifeOfVertigoList
              number='02'
              title='careers_vglife_title_2'
              desc='careers_vglife_desc_2'
            />
            <LifeOfVertigoBoxContainer>
              {lifeOfVertigoIconTop.map((image, index) => (
                <LifeOfVertigoBox
                  key={index}
                  icon={image}
                  title={lifeOfVertigoTop[index].title}
                  list={lifeOfVertigoTop[index].list}
                />
              ))}
            </LifeOfVertigoBoxContainer>
            <LifeOfVertigoList number='03' title='careers_vglife_title3' />
            <LifeOfVertigoBoxContainer>
              {lifeOfVertigoIconBottom.map((image, index) => (
                <LifeOfVertigoBox
                  key={index}
                  icon={image}
                  title={lifeOfVertigoBottom[index].title}
                  list={lifeOfVertigoBottom[index].list}
                  size='large'
                />
              ))}
            </LifeOfVertigoBoxContainer>
          </LifeOfVertigoContainer>

          {/* Recruitment section */}
          <RecruitmentContainer>
            <PageSectionTitleWrapper>
              <PageSectionTitle title='careers_section_3_title' />
            </PageSectionTitleWrapper>

            <RecruitmentDiagram />

            <RecruitmentStepsContainer>
              <MobileStepsTitleWrapper>
                <MobileStepsTitleNumber>
                  {circleData[0].number}
                </MobileStepsTitleNumber>
                <MobileStepsTile>
                  <FormattedMessage id={circleData[0].title} />
                </MobileStepsTile>
              </MobileStepsTitleWrapper>
              <StepList messages={stepsMessageFirst} />

              <MobileStepsTitleWrapper>
                <MobileStepsTitleNumber>
                  {circleData[1].number}
                </MobileStepsTitleNumber>
                <MobileStepsTile>
                  <FormattedMessage id={circleData[1].title} />
                </MobileStepsTile>
              </MobileStepsTitleWrapper>
              <StepList messages={stepsMessageSecond} />

              <MobileStepsTitleWrapper>
                <MobileStepsTitleNumber>
                  {circleData[2].number}
                </MobileStepsTitleNumber>
                <MobileStepsTile>
                  <FormattedMessage id={circleData[2].title} />
                </MobileStepsTile>
              </MobileStepsTitleWrapper>
              <StepList messages={stepsMessageThird} />

              <MobileStepsTitleWrapper>
                <MobileStepsTitleNumber>
                  {circleData[3].number}
                </MobileStepsTitleNumber>
                <MobileStepsTile>
                  <FormattedMessage id={circleData[3].title} />
                </MobileStepsTile>
              </MobileStepsTitleWrapper>
            </RecruitmentStepsContainer>
            <ProcessInfoContainer>
              <FormattedMessage
                id='process_info'
                values={{
                  linebreak: <br />,
                  b: (...chunks) => <b>{chunks}</b>,
                }}
              />
            </ProcessInfoContainer>
          </RecruitmentContainer>
        </PaddingWrapper>
        {/* Recruitment section */}
        {/* Hire Position section*/}
        <VisibilitySensor
          partialVisibility={true}
          delayedCall={true}
          resizeCheck={true}
        >
          {({ isVisible }) => (
            <Scroll.Element name='hiringSection' id='hiringSection'>
              <HiringPositionsContainer>
                <HirePositionTitleWrapper>
                  <PageSectionTitleWrapper padding={true}>
                    <PageSectionTitle title='careers_section_4_title' />
                  </PageSectionTitleWrapper>
                </HirePositionTitleWrapper>
                <HirePosition />
              </HiringPositionsContainer>
              <NavToHireSection isVisible={isVisible} />
            </Scroll.Element>
          )}
        </VisibilitySensor>
      </CareersPageInnerContainer>
      <ModalWindow>
        <HirePositionModal />
      </ModalWindow>
    </CareersPageContainer>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetchPostStart: () => dispatch(fetchPostStart()),
});

const mapStateToProps = createStructuredSelector({
  lifeOfVertigoIconTop: selectLifeOfVertigoImageTop,
  lifeOfVertigoIconBottom: selectLifeOfVertigoImageBottom,
  postItems: selectAllPostItems,
  circleData: selectCircleData,
});

export default connect(mapStateToProps, mapDispatchToProps)(CareersPage);
