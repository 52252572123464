import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectMapLocationList } from '../../redux/contactsPage/contactsPage.selector';

import ContactsMapPin from '../contacts-map-pin/contacts-map-pin.component';

import {
  AnalogClockContainer,
  ClockWrapper,
  ClockContent,
  ClockCenter,
  ClockHourIndicator,
  ClockMinutesIndicator,
  ClockSecondIndicator,
  ClockAmPm,
  ClockCity,
  PinWrapper,
} from './analog-clock.styles';

const AnalogClock = ({ time, index, mapLocationList }) => {
  const { city, hours, minutes, seconds, ampm } = time;
  const hoursDegrees = hours * 30 + minutes / 2;
  const minutesDegrees = minutes * 6 + seconds / 10;
  const secondsDegrees = seconds * 6;
  return (
    <AnalogClockContainer>
      <ClockWrapper>
        <ClockContent>
          <ClockCenter />
          <ClockHourIndicator hoursDegrees={hoursDegrees}></ClockHourIndicator>
          <ClockMinutesIndicator
            minutesDegrees={minutesDegrees}
          ></ClockMinutesIndicator>
          <ClockSecondIndicator
            secondsDegrees={secondsDegrees}
          ></ClockSecondIndicator>
          <ClockAmPm>{ampm}</ClockAmPm>
        </ClockContent>
      </ClockWrapper>
      <ClockCity>{city}</ClockCity>
      <PinWrapper>
        <ContactsMapPin
          country={mapLocationList[index].country}
          {...mapLocationList[index]}
        />
      </PinWrapper>
    </AnalogClockContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  mapLocationList: selectMapLocationList,
});

export default connect(mapStateToProps)(AnalogClock);
