import styled from 'styled-components';

export const NavToHireSectionContainer = styled.div`
  position: fixed;
  z-index: 1;
  cursor: pointer;
  right: 4%;
  bottom: 5%;
  height: 64px;
  width: 220px;
  border-radius: 34.5px;
  background-color: #ff8300;
  text-align: center;
  box-shadow: 5px 7px 15px 0 rgb(0 0 0 / 20%);
  font-family: ${({ theme }) => theme.notoSansKr};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: ${({ isVisible }) =>
    isVisible ? 'translateY(600%)' : 'translateY(0)'};
  transition: all 0.3s ease;
  @media ${({ theme }) => theme.tablet} {
    width: 180px;
    font-size: 14px;
    height: 50px;
  }
  @media ${({ theme }) => theme.mobile} {
    height: 45px;
    margin: 0 auto;
    right: 0;
    left: 0;
    bottom: 5%;
  }
`;

export const DownArrow = styled.img`
  margin-left: 10px;
`;
