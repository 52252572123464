import styled from 'styled-components';

import { LocaleMenuContainer } from '../locale-menu/locale-menu.styles';
import { ReactComponent as ChevronDown } from '../../assets/images/acsy/chevron-down.svg';
import { ReactComponent as ChevronUp } from '../../assets/images/acsy/chevron-up.svg';

export const GlobeMenuContainer = styled.div`
  cursor: pointer;
  padding: 15px;
  position: relative;
  width: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover ${LocaleMenuContainer} {
    display: flex;
  }
  @media ${({ theme }) => theme.laptop} {
    display: none;
  }
`;

export const CurrentLocale = styled.span`
  font-size: 1.0625rem;
  line-height: 1.4375rem;
  font-family: ${({ theme }) => theme.notoSansLight};
  color: ${({ footer }) => (footer ? '#858585' : '#bbb')};
  margin-left: 8.5px;
  margin-right: 3.5px;
  text-transform: uppercase;
`;

export const ChevronDownIcon = styled(ChevronDown)`
  fill: ${({ footer }) => (footer ? '#858585' : '#bbb')};
`;

export const ChevronUpIcon = styled(ChevronUp)`
  fill: #858585;
`;
