import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import LocaleMenu from '../locale-menu/locale-menu.component';

import { setLocale } from '../../redux/locales/locales.actions';

import { selectActiveLocale } from '../../redux/locales/locales.selector';

import {
  GlobeMenuContainer,
  CurrentLocale,
  ChevronDownIcon,
  ChevronUpIcon,
} from './globe-menu.styles';

import globeImgHeader from '../../assets/images/acsy/ic_global_white.png';
import globeImgFooter from '../../assets/images/acsy/ic_global_gray.png';

const GlobeMenu = ({ footer, activeLocale, setLocale }) => {
  const onClickLang = () => {
    if (footer) {
      if (activeLocale === 'en') {
        return setLocale('ko');
      }
      return setLocale('en');
    }
    return;
  };
  return (
    <GlobeMenuContainer onClick={onClickLang}>
      {footer ? (
        <img src={globeImgFooter} alt='globe icon' />
      ) : (
        <img src={globeImgHeader} alt='globe icon' />
      )}

      <CurrentLocale footer={footer}>{activeLocale}</CurrentLocale>

      {footer && activeLocale === 'ko' ? (
        <ChevronUpIcon />
      ) : (
        <ChevronDownIcon footer={footer} />
      )}
      {footer ? null : <LocaleMenu></LocaleMenu>}
    </GlobeMenuContainer>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setLocale: (locale) => dispatch(setLocale(locale)),
});

const mapStateToProps = createStructuredSelector({
  activeLocale: selectActiveLocale,
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobeMenu);
