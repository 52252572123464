import styled from 'styled-components';

export const GamesCardSmallContainer = styled.div`
  margin-bottom: 45px;
  position: relative;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const GameCardBackgroundImageWrapper = styled.div`
  position: relative;
  cursor: pointer;
  width: 230px;
  height: 230px;
  border-radius: 20px;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.3);
  overflow: hidden;
`;

export const GameCardBackgroundImage = styled.div`
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 0.3s ease;
  background-image: url(${({ backgroundImage }) => backgroundImage});
  &:hover {
    transform: scale(1.1);
  }
`;

export const GameCardNewTag = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  top: -10px;
  right: -10px;
  margin: auto;
  z-index: 1;
`;

export const GameCardTitle = styled.div`
  margin-top: 9px;
  width: 100%;
  text-align: center;
  font-family: ${({ theme }) => theme.notoSansKr};
  font-size: 1.1875rem;
  color: #1c1c1c;
`;

export const GameCardCategory = styled.div`
  margin-top: 9px;
  width: 100%;
  text-align: center;
  font-family: ${({ theme }) => theme.notoSansKr};
  font-size: 0.9rem;
  color: #858585;
`;

export const GameCardEnvContainer = styled.div`
  margin-top: 3.75rem;
  width: 100%;
  max-width: 6.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GameCardEnv = styled.div`
  width: 3.875rem;
  height: 1.5625rem;
  border-radius: 3px;
  background-color: #ccc;
  font-family: ${({ theme }) => theme.notoSansMedium};
  font-size: 0.75rem;
  text-align: center;
  color: #fff;
  margin: 0 2px;
  padding: 4px 8px;
  display: flex;
  justify-content: center;
`;

export const ViewMoreButton = styled.div`
  cursor: pointer;
  margin-top: 1rem;
  width: 100%;
  max-width: 6.625rem;
  padding: 5px 14px;
  font-family: ${({ theme }) => theme.notoSansLight};
  font-size: 0.875rem;
  color: #858585;
  border-radius: 5px;
  border: 1px solid #858585;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  letter-spacing: 0;
`;
