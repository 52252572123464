import React from 'react';

import PageTopImage from '../../components/page-top-image/page-top-image.component';
import ContactsQuestions from '../../components/contacts-questions/contacts-questions.components';
import ContactsMap from '../../components/contacts-map/contacts-map.component';
import ContactsTimeZone from '../../components/contacts-time-zone/contacts-time-zone.component';

import { ContactPageContainer } from './ContactsPage.styles';

const ContactsPage = ({ match }) => {
  return (
    <ContactPageContainer>
      <PageTopImage path={match.path} />
      <ContactsQuestions />
      <ContactsMap />
      <ContactsTimeZone />
    </ContactPageContainer>
  );
};

export default ContactsPage;
