import axios from 'axios';
import { takeLatest, call, put } from 'redux-saga/effects';

import { fetchPostSuccess, fetchPostFailure } from './posts.actions';

import PostsActionTypes from './posts.types';

// fetch news post
const fetchPosts = async () => {
  const postUrl = 'https://cms.vertigogames.com/cms/wp-json/wp/v2/posts';
  const params = { params: { per_page: 100 } };
  return await axios
    .get(postUrl, params)
    .then((res) => res)
    .catch((error) => {
      console.log(error.response);
      return error.response;
    });
};

export function* fetchPostAsync() {
  try {
    const response = yield call(fetchPosts);
    yield put(fetchPostSuccess(response.data));
  } catch (error) {
    yield put(fetchPostFailure(error.message));
  }
}

export function* fetchPostsStart() {
  yield takeLatest(PostsActionTypes.FETCH_POSTS_START, fetchPostAsync);
}
