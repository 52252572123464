import globalImg from '../../assets/images/aboutSix/global.png';
import teamImg from '../../assets/images/aboutSix/team.png';
import paymentImg from '../../assets/images/aboutSix/payment.png';
import locationImg from '../../assets/images/aboutSix/location.png';
import communityImg from '../../assets/images/aboutSix/community.png';
import solutionImg from '../../assets/images/aboutSix/solution.png';
// character images
import char1 from '../../assets/images/historyCharImg/history_img_1.png';
import char2 from '../../assets/images/historyCharImg/history_img_2.png';
import char3 from '../../assets/images/historyCharImg/history_img_3.png';
import char4 from '../../assets/images/historyCharImg/history_img_4.png';
import char5 from '../../assets/images/historyCharImg/history_img_5.png';

const INITIAL_STATE = {
  sixServices: [
    {
      image: globalImg,
    },
    {
      image: teamImg,
    },
    {
      image: paymentImg,
    },
    {
      image: locationImg,
    },
    {
      image: communityImg,
    },
    {
      image: solutionImg,
    },
  ],
  characterData: [char1, char2, char3, char4, char5],
};

const aboutPageReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default aboutPageReducer;
