import detectBrowserLanguage from 'detect-browser-language';

import LocalesActionTypes from './locales.types';

const detectBrowserLang = () => {
  if (detectBrowserLanguage().includes('ko')) {
    return 'ko';
  } else {
    return 'en';
  }
};

const INITIAL_STATE = {
  localeMenu: [
    { lang: 'korean', code: 'ko' },
    { lang: 'english', code: 'en' },
  ],
  activeLocale: detectBrowserLang(),
};

const localeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LocalesActionTypes.SET_LOCALE:
      return {
        ...state,
        activeLocale: action.payload,
      };
    default:
      return state;
  }
};

export default localeReducer;
