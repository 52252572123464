import styled, { css } from 'styled-components';

export const CharacterDialogue = styled.div`
  display: none;
  position: absolute;
  padding: 10px;
  background-color: #1c1c1c;
  font-size: 0.875rem;
  font-family: ${(props) => props.theme.notoSansKr};
  line-height: 1.25rem;
  font-weight: 300;
  color: #fff;
  box-shadow: 2px 15px 15px 0px rgba(0, 0, 0, 0.2);
`;

export const HistoryCharacterWrapper = css`
  position: absolute;
  margin: 0 auto;
  width: 18.75rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  &:hover {
    ${CharacterDialogue} {
      display: flex;
    }
  }
`;

export const HistoryCharacterContainer = styled.div`
  position: absolute;
  width: 100%;
  max-width: 56rem;
  height: 100%;
  top: 0;
  right: 0;
  > div:nth-child(1) {
    top: -150px;
    right: 140px;
    width: 350px;
    ${HistoryCharacterWrapper}
    ${CharacterDialogue} {
      right: 131px;
      bottom: 60px;
    }
  }
  > div:nth-child(2) {
    top: 555px;
    right: 140px;
    width: 350px;
    ${HistoryCharacterWrapper}
    ${CharacterDialogue} {
      right: 88px;
      bottom: 89px;
    }
  }
  > div:nth-child(3) {
    top: 776px;
    right: 0px;
    width: 350px;
    ${HistoryCharacterWrapper}
    ${CharacterDialogue} {
      right: 95px;
      bottom: 16px;
    }
  }
  > div:nth-child(4) {
    top: 1150px;
    right: 140px;
    width: 400px;
    ${HistoryCharacterWrapper}
    ${CharacterDialogue} {
      right: 65px;
      bottom: 110px;
    }
  }
  > div:nth-child(5) {
    top: 1424px;
    right: 0px;
    width: 400px;
    ${HistoryCharacterWrapper}
    ${CharacterDialogue} {
      width: 125px;
      right: 188px;
      bottom: 45px;
    }
  }

  @media ${({ theme }) => theme.tablet} {
    display: none;
  }
`;

export const CharacterImage = styled.img`
  z-index: 1;
`;
