import React from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectIsModalOpen } from '../../redux/modal/modal.selector';

import { closeModal } from '../../redux/modal/modal.actions';

import { ModalContainer } from './modal-window.styles';

Modal.setAppElement('#root');
//Modal.defaultStyles = {};
const ModalWindow = ({ isModalOpen, closeModal, children }) => {
  const customStyles = {
    content: {
      width: '100%',
      maxWidth: '1336px',
      padding: '40px',
      display: 'flex',
      inset: 'auto',
      justifyContent: 'center',
    },
  };
  return (
    <ModalContainer>
      <Modal
        style={customStyles}
        isOpen={isModalOpen}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        onRequestClose={closeModal}
      >
        {children}
      </Modal>
    </ModalContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  isModalOpen: selectIsModalOpen,
});
const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalWindow);
