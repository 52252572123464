import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import GameCard from '../game-card/game-card.component';
import Arrows from '../slick-arrows/arrow-component';

import { GameSlickSliderContainer } from './game-slick-slider.styles';

const settings = {
  infinite: false,
  dots: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  nextArrow: <Arrows direction='left' />,
  prevArrow: <Arrows direction='right' />,
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1210,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        arrows: false,
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 475,
      settings: {
        arrows: false,
        slidesToShow: 1,
        //centerMode: true,
        variableWidth: true,
        //centerPadding: 0,
      },
    },
  ],
};

const GameSlickSlider = ({ slideItems, section }) => {
  return (
    <GameSlickSliderContainer>
      <Slider {...settings}>
        {slideItems.map((slideItem, index) => (
          <GameCard
            key={slideItem.id}
            gameItem={slideItem}
            index={index}
            section={section}
          />
        ))}
      </Slider>
    </GameSlickSliderContainer>
  );
};

export default GameSlickSlider;
