import React from 'react';

import GnbHamburgerButton from './gnb-hamburger-button/gnb-hamburger-button.component';
import GnbMobileMenu from './gnb-mobile-menu/gnb-mobile-menu.component';

import {
  GnbTabletMobileContainer,
  VertigoLogo,
} from './gnb-tablet-mobile.styles';

const GnbTabletMobile = ({ isReachedTop, pageYOffset }) => {
  return (
    <GnbTabletMobileContainer
      isReachedTop={isReachedTop}
      pageYOffset={pageYOffset}
    >
      <VertigoLogo to='/' />
      <GnbHamburgerButton />
      <GnbMobileMenu />
    </GnbTabletMobileContainer>
  );
};

export default GnbTabletMobile;
