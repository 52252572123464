import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const FooterContainer = styled.div`
  height: 100%;
  min-height: 15.625rem;
  padding-left: 8.27%;
  padding-right: 8.27%;
  padding-top: 49px;
  padding-bottom: 125px;
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media ${({ theme }) => theme.tablet} {
    min-height: auto;
  }
`;

export const FooterFirstRow = styled.div`
  height: 31px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  @media ${({ theme }) => theme.tablet} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const FooterSecondRow = styled.div`
  margin-top: 55px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${({ theme }) => theme.tablet} {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const TrademarksWrapper = styled.div`
  width: 50%;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  @media ${({ theme }) => theme.tablet} {
    width: 100%;
    margin-top: 46px;
  }
`;

export const TradeMarks = styled.p`
  font-family: ${(props) => props.theme.notoSansLight};
  font-size: 0.75rem;
  line-height: 1.0625rem;
  color: #858585;
  text-align: left;
`;

export const TradeRight = styled.p`
  margin-top: 10px;
  font-family: ${(props) => props.theme.notoSansLight};
  font-size: 0.75rem;
  line-height: 1.0625rem;
  color: #858585;
  text-align: left;
`;

export const SnsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media ${({ theme }) => theme.tablet} {
    margin-top: 64px;
  }
`;

export const SnSFacebook = styled.a``;

export const VertigoLogoLink = styled(Link)``;
