import styled from 'styled-components';

export const FilterElement = styled.div`
  cursor: pointer;
  text-transform: uppercase;
  height: 50px;
  width: 100%;
  max-width: 333px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.notoSansKr};
  font-size: 1rem;
  font-weight: 500;
  line-height: 22px;
  border: 1px solid #1c1c1c;
  color: ${({ isActive }) => (isActive ? '#fff' : '#1c1c1c')};
  background-color: ${({ isActive }) => (isActive ? '#1c1c1c' : 'transparent')};
  @media ${({ theme }) => theme.tablet} {
    font-size: 14px;
  }
`;

export const HirePositionFilterContainer = styled.div`
  margin-top: 93px;
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${FilterElement}:nth-child(1) {
    border-right: 0;
    border-radius: 5px 0 0 5px;
  }
  ${FilterElement}:nth-child(3) {
    border-left: 0;
    border-radius: 0 5px 5px 0;
  }
  @media ${({ theme }) => theme.mobile} {
    display: none;
  }
`;
