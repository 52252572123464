import styled from 'styled-components';

export const ContactsEmailWrapper = styled.div`
  width: 100%;
  max-width: 290px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${({ theme }) => theme.tablet} {
    margin-top: 77px;
  }
  @media ${({ theme }) => theme.mobile} {
    margin-top: 28px;
  }
`;

export const ContactsEmailTitle = styled.div`
  margin-bottom: 20px;
  font-family: ${(props) => props.theme.notoSansKr};
  font-size: 22px;
  font-weight: 300;
  line-height: 33px;
  color: #1c1c1c;
  @media ${({ theme }) => theme.tablet} {
    font-size: 18px;
    margin-bottom: 11px;
  }
`;

export const ContactsEmailButton = styled.a`
  position: relative;
  height: 44px;
  width: 290px;
  border: 1px solid #1c1c1c;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContactsEmailAddress = styled.div`
  font-family: ${(props) => props.theme.notoSansBold};
  font-size: 1rem;
  line-height: 33px;
  color: #1c1c1c;
`;

export const ContactsButtonArrow = styled.img`
  position: absolute;
  right: 15px;
  width: 7px;
  margin: 0 auto;
`;
