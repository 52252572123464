import styled from 'styled-components';

export const SectionTitleContainer = styled.div`
  position: relative;
  width: auto;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const SectionSubTitle = styled.h6`
  font-size: 1rem;
  font-family: ${({ theme }) => theme.notoSansKr};
  font-weight: 300;
  line-height: 1.5rem;
  color: #1c1c1c;
  span {
    color: #29b06d;
  }
  @media ${({ theme }) => theme.tablet} {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const SectionTitle = styled.h1`
  font-size: 8.125rem;
  opacity: 0.5;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.notoSansBold};
  line-height: 11.0625rem;
  color: #ddd;

  @media ${({ theme }) => theme.tablet} {
    font-size: 100px;
    line-height: 136px;
  }
  @media ${({ theme }) => theme.mobile} {
    font-size: 40px;
    line-height: 82px;
  }
`;

const setWidth = ({ section }) => {
  if (section === 'games') {
    return 33.6875;
  }
  if (section === 'publish') {
    return 45.9375;
  }
  if (section === 'news') {
    return 33.6875;
  }
};

export const SectionDesc = styled.p`
  width: 100%;
  max-width: ${setWidth}rem;
  bottom: -1.3125rem;
  margin: 0 auto;
  position: absolute;
  font-family: ${({ theme }) => theme.notoSansKr};
  font-size: 2.125rem;
  font-weight: 500;
  line-height: 3.125rem;
  color: #1c1c1c;
  span {
    color: #29b06d;
  }
  @media ${({ theme }) => theme.tablet} {
    font-size: 28px;
    line-height: 41px;
  }
  @media ${({ theme }) => theme.mobile} {
    display: none;
  }
`;
