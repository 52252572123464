import React from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';

import PageSectionTitle from '../page-section-title/page-section-title.component';
import SnsIcon from '../sns-icon/sns-icon.component';

import { selectItemId } from '../../redux/modal/modal.selector';

import { closeModal } from '../../redux/modal/modal.actions';

import rightArrow from '../../assets/images/acsy/ic_arrow_small_next.png';

import {
    GameDetailsContainer,
    GameDetailsHeader,
    GameDetailsHeaderLogo,
    CloseButton,
    GameDetailsMainImageWrapper,
    GameDetailsMainImage,
    GameDetailsContent,
    GameDetailsRowWrapper,
    GameDetailsRowTitle,
    GameDetailsRowDesc,
    GameDetailsDesc,
    GameDeatilsWebsiteWrapper,
    GameDetailsWebsite,
    BtnArrow,
    SnsContainer,
} from './game-details.styles';

const GameDetails = ({ closeModal, gameItems, itemId }) => {
    const intl = useIntl();
    const message = intl.messages['game_content_data'];

    return (
        <GameDetailsContainer>
            <GameDetailsHeader>
                <GameDetailsHeaderLogo />
                <CloseButton onClick={() => closeModal()} />
            </GameDetailsHeader>
            <GameDetailsMainImageWrapper>
                <GameDetailsMainImage src={gameItems[itemId].modalImage} />
            </GameDetailsMainImageWrapper>
            <PageSectionTitle title={message[itemId].title} size="large" />
            <GameDetailsContent>
                <GameDetailsRowWrapper>
                    <GameDetailsRowTitle>Genre:</GameDetailsRowTitle>
                    <GameDetailsRowDesc>{message[itemId].genre}</GameDetailsRowDesc>
                </GameDetailsRowWrapper>
                <GameDetailsRowWrapper>
                    <GameDetailsRowTitle>Features:</GameDetailsRowTitle>
                    <GameDetailsRowDesc>{message[itemId].features}</GameDetailsRowDesc>
                </GameDetailsRowWrapper>
                {message[itemId].releaseDate ? (
                    <GameDetailsRowWrapper>
                        <GameDetailsRowTitle>Release Date:</GameDetailsRowTitle>
                        <GameDetailsRowDesc>{message[itemId].releaseDate}</GameDetailsRowDesc>
                    </GameDetailsRowWrapper>
                ) : null}
                {message[itemId].developer ? (
                    <GameDetailsRowWrapper>
                        <GameDetailsRowTitle>Developer:</GameDetailsRowTitle>
                        <GameDetailsRowDesc>{message[itemId].developer}</GameDetailsRowDesc>
                    </GameDetailsRowWrapper>
                ) : null}
                {message[itemId].publisher ? (
                    <GameDetailsRowWrapper>
                        <GameDetailsRowTitle>Publisher:</GameDetailsRowTitle>
                        <GameDetailsRowDesc>{message[itemId].publisher}</GameDetailsRowDesc>
                    </GameDetailsRowWrapper>
                ) : null}
            </GameDetailsContent>

            <GameDetailsDesc>{message[itemId].desc}</GameDetailsDesc>

            {message[itemId].gameBtn ? (
                <GameDeatilsWebsiteWrapper>
                    {message[itemId].gameBtn.map(({ title, url }, index) => (
                        <GameDetailsWebsite key={index} href={url} target="_blank">
                            {title}
                            <BtnArrow src={rightArrow} alt="arrow" />
                        </GameDetailsWebsite>
                    ))}
                </GameDeatilsWebsiteWrapper>
            ) : null}
            <SnsContainer>
                {gameItems[itemId].sns
                    ? gameItems[itemId].sns.map(({ name, link }, index) => (
                          <SnsIcon key={index} sns={name} link={link}></SnsIcon>
                      ))
                    : null}
            </SnsContainer>
        </GameDetailsContainer>
    );
};

const mapStateToProps = createStructuredSelector({
    itemId: selectItemId,
});

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GameDetails);
