import styled from 'styled-components';

export const NewsContentContainer = styled.div`
  margin-top: 8.75rem;
  margin-left: auto;
  margin-right: auto;
  justify-self: center;
  align-self: center;
  width: 100%;
  max-width: 60.625rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px 30px;
  grid-template-rows: 470px 470px;
  @media ${({ theme }) => theme.tablet} {
    max-width: 37rem;
  }
  @media ${({ theme }) => theme.mobile} {
    margin-top: 30px;
    //grid-template-columns: 1fr;
  }
`;
