import React, { useEffect, useState } from 'react';

import GnbFull from './gnb-full/gnb-full.component';
import GnbTabletMobile from './gnb-tablet-mobile/gnb-tablet-mobile.component';

import { GnbContainer } from './gnb.styles';

const GNB = () => {
  const [isReachedTop, setReachedTop] = useState(true);
  const [pageYOffset, setPageYOffset] = useState(0);

  const scrollListener = (e) => {
    e.preventDefault();
    setPageYOffset(window.pageYOffset);
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollListener);
    if (pageYOffset === 0) {
      setReachedTop(true);
    } else {
      setReachedTop(false);
    }

    return () => window.removeEventListener('scroll', scrollListener);
  }, [pageYOffset]);

  return (
    <GnbContainer>
      <GnbFull isReachedTop={isReachedTop} pageYOffset={pageYOffset} />
      <GnbTabletMobile isReachedTop={isReachedTop} pageYOffset={pageYOffset} />
    </GnbContainer>
  );
};

export default GNB;
