import styled from 'styled-components';

const getContainerHeight = ({ location }) => {
  if (location === '/') {
    return { height: '100px' };
  }
  if (location === '/about') {
    return { height: '353px' };
  }
  if (location === '/games') {
    return { height: '353px' };
  }
  if (location === '/news') {
    return { height: '353px' };
  }
  if (location === '/careers') {
    return { height: '409px' };
  }
  if (location === '/contacts') {
    return { height: '386px' };
  }
};

export const NavigateToTopContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${getContainerHeight}/* ${({ location }) =>
    location === '/' ? 'scroll-snap-align: center' : null}; */
  /* @media ${({ theme }) => theme.mobile} {
    scroll-snap-stop: unset;
  } */
`;

export const TopButtonButton = styled.div`
  cursor: pointer;
  width: 100%;
  max-width: 31px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ArrowUp = styled.img``;

export const TopText = styled.div`
  margin-top: 6px;
  font-family: ${({ theme }) => theme.notoSansRegular};
  font-size: 1rem;
  line-height: 22px;
  color: #1c1c1c;
`;
