import styled from 'styled-components';

export const LifeOfVertigoListWrapper = styled.div`
  margin-top: 4.1875rem;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media ${({ theme }) => theme.tablet} {
    margin-top: 58px;
  }
  @media ${({ theme }) => theme.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const LifeOfVertigoHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const LifeOfVertigoListNumber = styled.div`
  margin-right: 10px;
  font-family: ${({ theme }) => theme.notoSansLight};
  font-size: 2.125rem;
  line-height: 2.875rem;
  color: #bbb;
  @media ${({ theme }) => theme.tablet} {
    font-size: 28px;
    line-height: 38px;
  }
`;
export const LifeOfVertigoListTitle = styled.div`
  margin-right: 30px;
  font-family: ${({ theme }) => theme.notoSansKr};
  font-weight: 500;
  font-size: 1.375rem;
  line-height: 2.0625rem;
  color: #1c1c1c;
  @media ${({ theme }) => theme.tablet} {
    font-size: 18px;
    line-height: 27px;
  }
`;
export const LifeOfVertigoListDesc = styled.div`
  width: 100%;
  max-width: 42.5rem;
  font-family: ${({ theme }) => theme.notoSansKr};
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #858585;
  @media ${({ theme }) => theme.tablet} {
    font-size: 14px;
    line-height: 26px;
  }
`;
