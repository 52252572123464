import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { fetchPostStart } from '../../redux/posts/posts.actions';

import MainPageSectionTitle from '../main-page-section-title/MainPageSectionTitle.component';
import NewsSlickSlider from '../news-slick-slider/news-slick-slider.container';

import { MainNewsSectionContainer } from './main-news-section.styles';

const MainNewsSection = ({ subtitle, title, desc, fetchPostStart }) => {
  useEffect(() => {
    fetchPostStart();
  });
  return (
    <MainNewsSectionContainer>
      <MainPageSectionTitle subtitle={subtitle} title={title} desc={desc} />
      <NewsSlickSlider size='medium' />
    </MainNewsSectionContainer>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetchPostStart: () => dispatch(fetchPostStart()),
});

export default connect(null, mapDispatchToProps)(MainNewsSection);
