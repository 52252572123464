import ModalActionTypes from './modal.types';

export const openModal = (itemId) => ({
  type: ModalActionTypes.OPEN_MODAL,
  payload: itemId,
});

export const closeModal = () => ({
  type: ModalActionTypes.CLOSE_MODAL,
});
