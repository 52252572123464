import React from 'react';

import { ArrowContainer } from './arrow-styles';
import { ReactComponent as ChevronRight } from '../../assets/images/acsy/chevron-right.svg';
import { ReactComponent as ChevronLeft } from '../../assets/images/acsy/chevron-left.svg';

const Arrows = ({ direction, onClick, className }) => {
  return (
    <div className={`${direction}_arrow`}>
      {direction === 'left' ? (
        <ArrowContainer
          onClick={onClick}
          disabled={className.includes('slick-disabled')}
        >
          <ChevronRight />
        </ArrowContainer>
      ) : (
        <ArrowContainer
          onClick={onClick}
          disabled={className.includes('slick-disabled')}
        >
          <ChevronLeft />
        </ArrowContainer>
      )}
    </div>
  );
};

export default Arrows;
