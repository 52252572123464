import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { selectIsPostFetching } from '../../redux/posts/posts.selector';

import WithSpinner from '../with-spinner/with-spinner.component';
import NewsSlickSlider from './news-slick-slider.component';

const mapStateToProps = createStructuredSelector({
  isLoading: selectIsPostFetching,
});

const NewsSlickSliderContainer = compose(
  connect(mapStateToProps),
  WithSpinner
)(NewsSlickSlider);

export default NewsSlickSliderContainer;
