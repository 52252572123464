import React, { useState, useEffect } from 'react';

import {
  MainImageContainer,
  BackgroundContainer,
  MaskLayer,
  MainHeader,
  MainHeaderTitle,
  MainHeaderSubtitle,
} from './main-image.styles';

const MainImage = () => {
  const [scrollY, setScrollY] = useState(1);
  const scrollListener = () => {
    setScrollY(1 + window.pageYOffset / 6000);
  };
  useEffect(() => {
    window.addEventListener('scroll', scrollListener);
    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  });
  //console.log(mount);
  return (
    <MainImageContainer>
      <BackgroundContainer scrollY={scrollY}>
        <MaskLayer />
        <MainHeader>
          <MainHeaderTitle>
            DELIVERING THE ULTIMATE GAME EXPERIENCE
          </MainHeaderTitle>
          <MainHeaderSubtitle>VERTIGO GAMES</MainHeaderSubtitle>
        </MainHeader>
      </BackgroundContainer>
    </MainImageContainer>
  );
};

export default MainImage;
