import styled from 'styled-components';

export const GnbContainer = styled.div`
  z-index: 9999;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media ${({ theme }) => theme.mobile} {
    height: 46px;
  }
`;
