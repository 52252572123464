import styled from 'styled-components';

export const PageSectionTitleContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: ${({ linePosition }) =>
    linePosition === 'center' ? 'column-reverse' : 'row'};
  align-items: ${({ linePosition }) =>
    linePosition === 'center' ? 'center' : 'flex-end'};
  @media ${({ theme }) => theme.mobile} {
    align-items: ${({ linePosition }) =>
      linePosition === 'center' ? 'center' : 'flex-start'};
    flex-direction: column-reverse;
  }
`;

export const PageSectionLine = styled.span`
  margin-top: ${({ linePosition }) =>
    linePosition === 'center' ? '11px' : 'auto'};
  margin-bottom: ${({ linePosition }) =>
    linePosition === 'center' ? '0' : '-0.625rem'};
  height: 0.125rem;
  width: 2.5rem;
  background-color: ${({ color }) =>
    color === 'green' ? '#29b06d' : '#ff8300'};
  @media ${({ theme }) => theme.mobile} {
    margin-top: ${({ linePosition }) =>
      linePosition === 'center' ? '10px' : 'auto'};
    width: 26px;
    //margin-bottom: 2rem;
  }
`;

export const PageSectionText = styled.h3`
  font-family: ${(props) =>
    props.size === 'large'
      ? props.theme.notoSansMedium
      : props.theme.notoSansKr};
  font-weight: 300;
  font-size: ${(props) => (props.size === 'large' ? '34px' : '1.375rem')};
  color: #1c1c1c;
  > span {
    margin-left: 8px;
    color: #29b06d;
  }

  @media ${({ theme }) => theme.tablet} {
    font-size: 18px;
  }
`;
