import RoutesNav from './pages/Routes';

function App() {
  return (
    <div className='App'>
      <RoutesNav />
    </div>
  );
}

export default App;
