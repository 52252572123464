import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  ContactsMapPinContainer,
  MapPinImage,
  ContactMapDetailWrapper,
  Location,
  Address,
  Email,
  Contact,
  Phone,
  MapButton,
  MobileLinkButton,
} from './contacts-map-pin.styles';

const ContactsMapPin = ({ country, ...otherProps }) => {
  const { location, address, email, phoneNumber, mapLink } = otherProps;
  return (
    <ContactsMapPinContainer>
      <MapPinImage />
      <MobileLinkButton href={mapLink} target='_blank' />
      <ContactMapDetailWrapper>
        {country.includes('Korea') ? (
          <div>
            <Location>
              <FormattedMessage id={location}></FormattedMessage>
            </Location>
            <Address>
              <FormattedMessage id={address}></FormattedMessage>
            </Address>
          </div>
        ) : (
          <div>
            <Location>{location}</Location>
            <Address>{address}</Address>
          </div>
        )}

        <Email href={`mailto:${email}`}>{email}</Email>
        <Contact href='https://www.papayaplay.com' target='_blank'>
          https://www.papayaplay.com
        </Contact>
        <Phone>{phoneNumber}</Phone>
        <MapButton href={mapLink} target='_blank'>
          View More
        </MapButton>
      </ContactMapDetailWrapper>
    </ContactsMapPinContainer>
  );
};

export default ContactsMapPin;
