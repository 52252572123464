import styled from 'styled-components';

export const HamburgetButtonContainer = styled.div`
  position: absolute;
  right: 30px;
  top: 30px;
  width: 30px;
  height: 30px;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  z-index: 1;
  @media ${({ theme }) => theme.mobile} {
    top: 12px;
  }
`;

export const ButtonLine = styled.span`
  display: block;
  position: absolute;
  height: 1px;
  width: 100%;
  background: #fff;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
  &:nth-child(1) {
    top: ${({ mobileMenu }) => (mobileMenu ? '3px' : '0px')};
    left: ${({ mobileMenu }) => (mobileMenu ? '4px' : null)};
    transform-origin: left center;
    transform: ${({ mobileMenu }) => (mobileMenu ? 'rotate(45deg)' : null)};
  }
  &:nth-child(2) {
    top: 10px;
    transform-origin: left center;
    opacity: ${({ mobileMenu }) => (mobileMenu ? 0 : 1)};
  }
  &:nth-child(3) {
    top: ${({ mobileMenu }) => (mobileMenu ? '24px' : '20px')};
    left: ${({ mobileMenu }) => (mobileMenu ? '4px' : null)};
    transform-origin: left center;
    transform: ${({ mobileMenu }) => (mobileMenu ? 'rotate(-45deg)' : null)};
  }
`;
