import { createSelector } from 'reselect';

const selectModal = (state) => state.modal;

export const selectIsModalOpen = createSelector(
  [selectModal],
  (modal) => modal.isModalOpen
);

export const selectItemId = createSelector(
  [selectModal],
  (modal) => modal.itemId
);
