import { createSelector } from 'reselect';

const selectPageTopContents = (state) => state.pageTopContents;

export const selectPageTopContent = createSelector(
  [selectPageTopContents],
  (contents) => contents.pageTopContentItems
);

export const selectCurrentPageTopContent = (urlParam) =>
  createSelector([selectPageTopContent], (pageTopContentItems) =>
    pageTopContentItems.find(({ path }) => path === urlParam)
  );
