import React, { useState } from 'react';
import { connect } from 'react-redux';
import parse from 'html-react-parser';

import { openModal } from '../../redux/modal/modal.actions';

import downArrow from '../../assets/images/acsy/ic_arrow_mid2_blk_down.png';
import RightArrowImage from '../../assets/images/acsy/ic_arrow_small_next.png';

import {
  HireAccordionMenuWrapper,
  AccordionHeader,
  HeaderWrapper,
  HeaderTitle,
  HeaderDepartment,
  HeaderLocation,
  ArrowWrapper,
  ArrowImage,
  AccordionBody,
  AccordionBodyContents,
  ApplyButton,
  ButtonRightArrow,
  MobileHeaderArrow,
  BodyWrapper,
  MobileAccordionMenuWrapper,
} from './hire-accordion-menu.styles';

const HireAccordionMenu = ({ post, openModal }) => {
  const [isMenuOpen, toggleMenu] = useState(false);
  const toggleAccordionMenu = () => {
    toggleMenu(!isMenuOpen);
  };
  const { title, hire_department, hire_location, content } = post;
  return (
    <HireAccordionMenuWrapper>
      <AccordionHeader onClick={() => toggleAccordionMenu()}>
        <MobileAccordionMenuWrapper
          onClick={() => openModal(post.id)}
        ></MobileAccordionMenuWrapper>
        <HeaderWrapper>
          <HeaderTitle>{parse(title.rendered)}</HeaderTitle>
          <MobileHeaderArrow
            src={RightArrowImage}
            alt='arrow'
          ></MobileHeaderArrow>
        </HeaderWrapper>
        <BodyWrapper>
          <HeaderDepartment>{hire_department}</HeaderDepartment>
          <HeaderLocation location={hire_location[0]}>
            {hire_location[0]}
          </HeaderLocation>
        </BodyWrapper>
        <ArrowWrapper>
          <ArrowImage src={downArrow} alt='arrow' />
        </ArrowWrapper>
      </AccordionHeader>
      <AccordionBody isMenuOpen={isMenuOpen}>
        <AccordionBodyContents isMenuOpen={isMenuOpen}>
          {parse(content.rendered)}
          <ApplyButton href='mailto:job@vertigogames.com'>
            Apply Now
            <ButtonRightArrow src={RightArrowImage} alt='right arrow' />
          </ApplyButton>
        </AccordionBodyContents>
      </AccordionBody>
    </HireAccordionMenuWrapper>
  );
};

const mapDispatchToProps = (dispatch) => ({
  openModal: (index) => dispatch(openModal(index)),
});

export default connect(null, mapDispatchToProps)(HireAccordionMenu);
