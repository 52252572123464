import React from 'react';

import HirePositionFilter from '../hire-position-filter/hire-position-filter.component';
import HireAccordion from '../hire-accrodion/hire-accordion.component';
import MobileHirePositionFilter from '../mobile-hire-position-filter/mobile-hire-position-filter.component';

import { HirePositionContainer } from './hire-position.styles';

const HirePosition = () => {
  return (
    <HirePositionContainer>
      <HirePositionFilter />
      <MobileHirePositionFilter />
      <HireAccordion />
    </HirePositionContainer>
  );
};

export default HirePosition;
