import React from 'react';
import { FormattedMessage } from 'react-intl';

import vertigoSymbolImg from '../../assets/images/bg/simbol_big.png';
import {
  MainSubSectionContainer,
  MainSubContentContainer,
  VertigoSymbolImageWrapper,
  MainSubContentTitle,
  MainSubContentDesc,
  StatContainer,
  StatWrapper,
  StatNumber,
  StatText,
  MainSubContentTitleMobile,
  MainSubContentDescMobile,
} from './MainSubSection.styles';

const STAT_DATA = [
  {
    number: '42+',
    text: 'countries',
  },
  {
    number: '13,000,000+',
    text: 'players',
  },
  {
    number: '3',
    text: 'offices',
  },
];
// staticstic component
const MainSubStatWrapper = ({ number, text }) => {
  return (
    <StatWrapper text={text}>
      <StatNumber>{number}</StatNumber>
      <StatText>{text}</StatText>
    </StatWrapper>
  );
};

const MainSubSection = () => {
  return (
    <MainSubSectionContainer>
      <MainSubContentContainer>
        <VertigoSymbolImageWrapper>
          <img src={vertigoSymbolImg} alt='vertigo symbol' />
        </VertigoSymbolImageWrapper>
        <MainSubContentTitle>
          <FormattedMessage
            id='main_section_2_title'
            values={{ linebreak: <br /> }}
          />
        </MainSubContentTitle>
        <MainSubContentTitleMobile>
          <FormattedMessage
            id='main_section_2_title_mobile'
            values={{ linebreak: <br /> }}
          />
        </MainSubContentTitleMobile>
        <MainSubContentDesc>
          <FormattedMessage
            id='main_section_2_desc'
            values={{ linebreak: <br /> }}
          />
        </MainSubContentDesc>
        <MainSubContentDescMobile>
          <FormattedMessage
            id='main_section_2_desc_mobile'
            values={{ linebreak: <br /> }}
          />
        </MainSubContentDescMobile>
        <StatContainer>
          {STAT_DATA.map(({ number, text }) => (
            <MainSubStatWrapper key={number} number={number} text={text} />
          ))}
        </StatContainer>
      </MainSubContentContainer>
    </MainSubSectionContainer>
  );
};

export default MainSubSection;
