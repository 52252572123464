import styled from 'styled-components';
import { Link } from 'react-router-dom';

import vertigoSymbol from '../../../assets/images/acsy/vertigo_symbol.png';

export const GnbTabletMobileContainer = styled.div`
  width: 100%;
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  opacity: 1;
  background-color: #000;
  background-color: ${({ isReachedTop }) =>
    isReachedTop ? 'transparent' : '#000'};
  @media ${({ theme }) => theme.tablet} {
    display: flex;
  }
`;

export const VertigoLogo = styled(Link)`
  width: 28px;
  height: 28px;
  background-image: url(${vertigoSymbol});
`;
