const INITIAL_STATE = {
  emailAddressList: [
    {
      title: 'email_list_title_1',
      email: 'biz@vertigogames.com',
    },
    {
      title: 'email_list_title_2',
      email: 'contact@vertigogames.com',
    },
    {
      title: 'email_list_title_3',
      email: 'job@vertigogames.com',
    },
  ],
  mapLocationList: [
    {
      country: 'Korea',
      location: 'map_data_korea_location',
      address: 'map_data_korea_address',
      email: 'contact@vertigogames.com',
      phoneNumber: 'TEL +82+2-2051-9599',
      mapLink: 'https://goo.gl/maps/mh8UtC4StXAGeGMA7',
    },
    {
      country: 'Canada',
      location: 'Vancouver, Canada',
      address: 'Burnaby, BC V5C CANADA',
      contact: 'www.papayaplay.com',
      phoneNumber: '',
      mapLink: 'https://goo.gl/maps/4LedAm4kQNL5LZeXA',
    },
    {
      country: 'Singapore',
      location: '',
      address: 'Singapore 068808',
      contact: 'www.papayaplay.com',
      phoneNumber: '',
      mapLink: 'https://goo.gl/maps/5uRGvrSKWJT76Wgp7',
    },
  ],
};

const contactsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default contactsReducer;
