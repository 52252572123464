import styled from 'styled-components';

export const MobileHirePositionFilterContainer = styled.div`
  position: relative;
  display: none;
  width: 240px;
  flex-direction: column;
  @media ${({ theme }) => theme.mobile} {
    margin-top: 93px;
    display: flex;
  }
`;

export const FilterActive = styled.div`
  position: relative;
  width: 100%;
  height: 50px;
  border: 1px solid #1c1c1c;
  border-radius: 5px;
  background-color: #1c1c1c;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10.5px;
  cursor: pointer;
`;

export const ActiveOffice = styled.div`
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.notoSansMedium};
  font-size: 1rem;
  color: #fff;
`;

export const ArrowMenu = styled.img`
  position: absolute;
  width: 19px;
  right: 15px;
  margin: auto;
  transition: transform 0.2s;
`;

export const FilterList = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  display: ${({ mobileFilterMenu }) => (mobileFilterMenu ? 'flex' : 'none')};
  flex-direction: column;
  background-color: white;
  font-weight: bold;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

export const FilterButton = styled.div`
  cursor: pointer;
  border-bottom: 1px solid #1c1c1c;
  width: 240px;
  height: 50px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.notoSansKr};
  font-size: 1rem;
  font-weight: 500;
  line-height: 19px;
  color: #000;
`;
