import styled from 'styled-components';

export const LifeOfVertigoBoxWrapper = styled.div`
  padding-top: 3.0625rem;
  padding-left: 3px;
  padding-right: 3px;
  height: ${({ size }) => (size === 'large' ? '22.5rem' : '18.75rem')};
  width: 19.125rem;
  border-radius: 20px;
  background-color: #f6f6f6;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media ${({ theme }) => theme.tablet} {
    margin-top: 20px;
    width: 100%;
    flex-direction: row;
    height: auto;
    padding: 30px;
  }
  @media ${({ theme }) => theme.mobile} {
    flex-direction: column;
  }
`;

export const BoxIcon = styled.div`
  width: 40px;
  height: 40px;
  background-image: url(${({ icon }) => icon});
`;

export const BoxTitle = styled.div`
  margin-top: 11px;
  text-align: center;
  font-family: ${({ theme }) => theme.notoSansKr};
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 2.0625rem;
  color: #1c1c1c;
  @media ${({ theme }) => theme.tablet} {
    margin-top: 0;
    margin-left: 15px;
    font-size: 18px;
    line-height: 27px;
  }
  @media ${({ theme }) => theme.tablet} {
    margin-left: 0px;
  }
`;

export const BoxListWrapper = styled.div`
  margin-top: 5px;
  flex-direction: column;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${({ theme }) => theme.tablet} {
    margin-left: 40px;
    align-items: flex-start;
  }
  @media ${({ theme }) => theme.mobile} {
    margin-left: 0;
    align-items: center;
  }
`;

export const BoxList = styled.div`
  font-size: 1rem;
  font-family: ${({ theme }) => theme.notoSansKr};
  font-weight: 300;
  line-height: 2.0625rem;
  color: #858585;
  text-align: center;
  @media ${({ theme }) => theme.tablet} {
    font-size: 14px;
    line-height: 26px;
    text-align: left;
  }
  @media ${({ theme }) => theme.tablet} {
    text-align: center;
  }
`;
