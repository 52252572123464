import styled from 'styled-components';

export const ContactsQuestionsContainer = styled.div`
  margin-top: 142px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const QuestionsWrapper = styled.div`
  text-align: center;
  width: 100%;
  height: 174px;
  background-color: #f6f6f6;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.notoSansKr};
  font-size: 1rem;
  font-weight: 400;
  line-height: 37px;
  color: #1c1c1c;
  padding: 0 31px;
  @media ${({ theme }) => theme.mobile} {
    font-family: 14px;
    line-height: 29px;
  }
`;

export const QuestionsEmailContainer = styled.div`
  margin-top: 117px;
  flex-wrap: wrap;
  max-width: 1036px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media ${({ theme }) => theme.tablet} {
    flex-direction: column;
    margin-top: 0px;
  }
`;
