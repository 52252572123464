import CareersPageActionTypes from './careersPage.types';
import { enableFilterMenu } from './careersPage.utils';

import penImg from '../../assets/images/careers_img/ic_pen.png';
import leadershipImg from '../../assets/images/careers_img/ic_edu_leadership.png';
import eduEthicsImg from '../../assets/images/careers_img/ic_edu_ethics.png';
import healthImg from '../../assets/images/careers_img/ic_health.png';
import lifeImg from '../../assets/images/careers_img/ic_life.png';
import cultureImg from '../../assets/images/careers_img/ic_culture.png';

const INITIAL_STATE = {
  lifeOfVertigoIconTop: [penImg, leadershipImg, eduEthicsImg],
  lifeOfVertigoIconBottom: [healthImg, lifeImg, cultureImg],
  circleData: [
    { size: 'small', number: '01', title: 'hire_process_list_title_1' },
    { size: 'large', number: '02', title: 'hire_process_list_title_2' },
    { size: 'medium', number: '03', title: 'hire_process_list_title_3' },
    { size: 'small', number: '04', title: 'hire_process_list_title_4' },
  ],
  officeFilterData: [
    { office: 'SEOUL', number: 0, isActive: true },
    { office: 'VANCOUVER', number: 0, isActive: false },
    { office: 'SINGAPORE', number: 0, isActive: false },
  ],
  mobileFilterMenu: false,
};

const careersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CareersPageActionTypes.ENABLE_OFFICE:
      return {
        ...state,
        officeFilterData: enableFilterMenu(
          state.officeFilterData,
          action.payload
        ),
        mobileFilterMenu: false,
      };
    case CareersPageActionTypes.HANDLE_MOBILE_FILTER_MENU:
      return {
        ...state,
        mobileFilterMenu: !state.mobileFilterMenu,
      };
    default:
      return state;
  }
};

export default careersReducer;
