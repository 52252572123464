import { createSelector } from 'reselect';

const selectAboutPage = (state) => state.aboutPage;

export const selectSixServices = createSelector(
  [selectAboutPage],
  (aboutPage) => aboutPage.sixServices
);

export const selectHistories = createSelector(
  [selectAboutPage],
  (aboutPage) => aboutPage.histories
);

export const selectCharacterImages = createSelector(
  [selectAboutPage],
  (aboutPage) => aboutPage.characterData
);
