import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  CircleContainer,
  CircleWrapper,
  CircleNumber,
  CircleTitle,
} from './recuitment-circle.styles';

const RecruitmentCircle = ({ size, number, title }) => {
  return (
    <CircleContainer size={size}>
      <CircleWrapper size={size}>
        <CircleNumber size={size}>{number}</CircleNumber>
        <CircleTitle size={size}>
          <FormattedMessage id={title} />
        </CircleTitle>
      </CircleWrapper>
    </CircleContainer>
  );
};

export default RecruitmentCircle;
