import styled from 'styled-components';

export const BoxContainer = styled.div`
  width: 15.625rem;
  height: 168px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-self: center;
  justify-self: center;
`;

export const BoxTitle = styled.div`
  margin-bottom: 0.625rem;
  text-align: center;
  font-family: ${(props) => props.theme.notoSansKr};
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #000;
`;

export const BoxImage = styled.div`
  height: 8.375rem;
  width: 15.625rem;
  border-radius: 8px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${(props) => props.backgroundImage});
`;
