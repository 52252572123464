import React from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";

import { openModal } from "../../redux/modal/modal.actions";

import newTagImage from "../../assets/images/acsy/gamethumb_new.png";

import {
    GamesCardSmallContainer,
    GameCardBackgroundImageWrapper,
    GameCardBackgroundImage,
    GameCardNewTag,
    GameCardTitle,
    GameCardCategory,
    GameCardEnvContainer,
    GameCardEnv,
    ViewMoreButton,
} from "./games-card-small.styles";

const GamesCardSmall = ({ game, index, openModal }) => {
    const { bgImage2, newTag, env } = game;
    const intl = useIntl();
    const message = intl.messages["papaya_game_data"];
    return (
        <GamesCardSmallContainer>
            {newTag ? <GameCardNewTag src={newTagImage} alt="New" /> : null}
            <GameCardBackgroundImageWrapper onClick={() => openModal(index)}>
                <GameCardBackgroundImage
                    backgroundImage={bgImage2}
                ></GameCardBackgroundImage>
            </GameCardBackgroundImageWrapper>
            <GameCardTitle>{message[index].title}</GameCardTitle>
            <GameCardCategory>{message[index].subtitle}</GameCardCategory>
            <GameCardEnvContainer>
                {env.map((item, index) => (
                    <GameCardEnv key={index}>{item}</GameCardEnv>
                ))}
            </GameCardEnvContainer>
            <ViewMoreButton onClick={() => openModal(index)}>
                View More
            </ViewMoreButton>
        </GamesCardSmallContainer>
    );
};

const mapDispatchToProps = (dispatch) => ({
    openModal: (index) => dispatch(openModal(index)),
});

export default connect(null, mapDispatchToProps)(GamesCardSmall);
