import styled from 'styled-components';
import { Link } from 'react-router-dom';

import vertigoLogo from '../../../../assets/images/bg/vertigogames_w.png';

export const MobileMenuContainer = styled.div`
  box-shadow: -3px 0 10px 0 rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  right: ${({ mobileMenu }) => (mobileMenu ? 0 : '-100vw')};
  padding-top: 24px;
  padding-right: 0;
  width: 100vw;
  height: 100vh;
  background: #000;
  cursor: pointer;
  flex-direction: column;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: all 0.5s ease 0s;
`;

export const VertigoLogo = styled(Link)`
  width: 156px;
  height: 32px;
  background-image: url(${vertigoLogo});
`;
