import styled from 'styled-components';

export const NewsSlickSliderContainer = styled.div`
  width: 100%;
  margin-top: 45px;
  .slick-slider {
    .left_arrow,
    .right_arrow {
      position: absolute;
      top: -43px;
      margin: 0 auto;
    }
    .left_arrow {
      right: 150px;
    }
    .right_arrow {
      right: 210px;
    }
  }
  @media ${({ theme }) => theme.mobile} {
    margin-top: 0;
  }
`;
