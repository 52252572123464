import PostsActionTypes from './posts.types';

const INITIAL_STATE = {
  postsData: [],
  isFetching: true,
  errorMessage: undefined,
};

const postsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PostsActionTypes.FETCH_POSTS_START:
      return {
        ...state,
        isFetching: true,
      };
    case PostsActionTypes.FETCH_POSTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        postsData: action.payload,
      };
    case PostsActionTypes.FETCH_POSTS_FAILURE:
      return {
        ...state,
        isFetching: false,
        postsData: action.payload,
      };
    default:
      return state;
  }
};

export default postsReducer;
