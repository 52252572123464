import React from 'react';
import parse from 'html-react-parser';

import {
  NewsCardContainer,
  NewsCardImgWrapper,
  NewsCardContentWrapper,
  NewsCardTag,
  NewsTitle,
  NewsSourceContainer,
  NewsPublisher,
  NewsData,
  NewsDesc,
  NewsButton,
} from './news-card.styles';

const NewsCard = ({
  size,
  news_image,
  news_tag,
  news_publisher,
  news_publish_date,
  title,
  content,
  news_origin_link,
}) => {
  return (
    <NewsCardContainer size={size}>
      {news_image ? (
        <NewsCardImgWrapper bgImg={news_image} size={size}></NewsCardImgWrapper>
      ) : null}

      <NewsCardContentWrapper bgImg={news_image}>
        <NewsCardTag tag={news_tag[0]} bgImg={news_image}>
          {news_tag}
        </NewsCardTag>
        <NewsTitle>{parse(title.rendered)}</NewsTitle>
        <NewsSourceContainer size={size}>
          <NewsPublisher>{news_publisher}</NewsPublisher>
          <NewsData>{news_publish_date}</NewsData>
        </NewsSourceContainer>
        <NewsDesc>{parse(content.rendered)}</NewsDesc>
      </NewsCardContentWrapper>
      <NewsButton target='_blank' href={news_origin_link}>
        View more
      </NewsButton>
    </NewsCardContainer>
  );
};

export default NewsCard;
