import styled, { css } from 'styled-components';

export const MainSubSectionContainer = styled.div`
  //scroll-snap-align: center;
  width: 100%;
  height: 100vh;
  background: linear-gradient(250.85deg, #ffc835 -20%, #ff8300 85%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 60px;
  /* @media ${({ theme }) => theme.mobile} {
    padding: 0 40px;
  } */
`;

export const MainSubContentContainer = styled.div`
  width: 100%;
  max-width: 37.5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media ${({ theme }) => theme.mobile} {
    align-items: center;
  }
`;

export const VertigoSymbolImageWrapper = styled.div`
  width: 100%;
  max-width: 5.625rem;
  > img {
    width: 100%;
  }
  @media ${({ theme }) => theme.tablet} {
    max-width: 80px;
  }
  @media ${({ theme }) => theme.mobile} {
    max-width: 40px;
  }
`;

const subContentTitleStyle = css`
  margin-top: 1.875rem;
  width: 100%;
  max-width: 28.75rem;
  text-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
  font-family: ${({ theme }) => theme.notoSansKr};
  font-weight: 700;
  color: #1c1c1c;
`;

export const MainSubContentTitle = styled.h3`
  font-size: 2.125rem;
  line-height: 3.125rem;
  ${subContentTitleStyle}
  @media ${({ theme }) => theme.tablet} {
    font-size: 28px;
    line-height: 41px;
  }
  @media ${({ theme }) => theme.mobile} {
    display: none;
  }
`;

// mobile style
export const MainSubContentTitleMobile = styled.h3`
  display: none;
  ${subContentTitleStyle}
  @media ${({ theme }) => theme.mobile} {
    display: block;
    font-size: 20px;
    line-height: 29px;
    text-align: center;
  }
`;

const subContentDescStyle = css`
  margin-top: 1.875rem;
  font-family: ${({ theme }) => theme.notoSansKr};
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.813rem;
  color: #fff;
`;

export const MainSubContentDesc = styled.h5`
  font-size: 1rem;
  line-height: 1.813rem;
  ${subContentDescStyle}
  @media ${({ theme }) => theme.tablet} {
    font-size: 14px;
    line-height: 26px;
  }
  @media ${({ theme }) => theme.mobile} {
    display: none;
  }
`;

export const MainSubContentDescMobile = styled.h5`
  display: none;
  ${subContentDescStyle}
  @media ${({ theme }) => theme.mobile} {
    text-align: center;
    display: block;
  }
`;

export const StatContainer = styled.div`
  margin-top: 2.5625rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

// const setWidth = ({ text }) => {
//   if (text === 'countries') {
//     return 90;
//   }
//   if (text === 'players') {
//     return 180;
//   }
//   if (text === 'offices') {
//     return 62;
//   }
// };

export const StatWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StatNumber = styled.span`
  text-align: center;
  margin-bottom: 0.4375rem;
  width: 100%;
  font-family: ${({ theme }) => theme.notoSansBold};
  font-size: 1.875rem;
  line-height: 1.8125rem;
  color: #fff;
  @media ${({ theme }) => theme.tablet} {
    font-size: 26px;
    line-height: 29px;
  }
  @media ${({ theme }) => theme.mobile} {
    margin-bottom: 4px;
    font-size: 18px;
  }
`;

export const StatText = styled.span`
  text-align: center;
  width: 100%;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.8125rem;
  color: #fff;
  font-family: ${({ theme }) => theme.notoSansLight};
  @media ${({ theme }) => theme.tablet} {
    font-size: 14px;
    line-height: 19px;
  }
  @media ${({ theme }) => theme.mobile} {
    font-size: 12px;
  }
`;
