import styled from 'styled-components';

export const GameSlickSliderContainer = styled.div`
  width: 100%;
  margin-top: 45px;
  .slick-slider {
    .slick-list {
      .slick-track {
        margin-left: 0;
      }
    }
    .left_arrow,
    .right_arrow {
      position: absolute;
      top: -43px;
      margin: 0 auto;
    }
    .left_arrow {
      right: 150px;
    }
    .right_arrow {
      right: 210px;
    }
  }
  @media ${({ theme }) => theme.mobile} {
    margin-top: 0;
  }
`;
