import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  ContactsEmailWrapper,
  ContactsEmailTitle,
  ContactsEmailButton,
  ContactsEmailAddress,
  ContactsButtonArrow,
} from './contacts-email.styles';

import rightArrow from '../../assets/images/acsy/ic_arrow_small_next.png';

const ContactsEmail = ({ title, email }) => {
  return (
    <ContactsEmailWrapper>
      <ContactsEmailTitle>
        <FormattedMessage id={title} />
      </ContactsEmailTitle>
      <ContactsEmailButton href={`mailto:${email}`}>
        <ContactsEmailAddress>{email}</ContactsEmailAddress>
        <ContactsButtonArrow src={rightArrow} alt='right arrow' />
      </ContactsEmailButton>
    </ContactsEmailWrapper>
  );
};

export default ContactsEmail;
