import React from 'react';

import GnbElement from '../GNB/gnb-element/gnb-element.component';

import {
  FooterContainer,
  FooterFirstRow,
  VertigoLogoLink,
  FooterSecondRow,
  TrademarksWrapper,
  TradeMarks,
  TradeRight,
  SnsWrapper,
  SnSFacebook,
} from './footer.styles';

import vertigoLogoImage from '../../assets/images/acsy/vertigogames_b.png';
import facebookLogoImage from '../../assets/images/acsy/ic_sns2_facebook_fade.png';

const getYear = () => {
  const thisDate = new Date();
  const thisYear = thisDate.getFullYear();
  return thisYear;
};

const Footer = () => {
  return (
    <FooterContainer>
      <FooterFirstRow>
        <VertigoLogoLink to='/'>
          <img src={vertigoLogoImage} alt='Vertigo Logo' />
        </VertigoLogoLink>
        <GnbElement footer='true' />
      </FooterFirstRow>
      <FooterSecondRow>
        <TrademarksWrapper>
          <TradeMarks>
            All trademarks referenced herein are the properties of their
            respective owners.
          </TradeMarks>
          <TradeRight>
            ©{getYear()} Vertigo Games Inc. All Rights Reserved
          </TradeRight>
        </TrademarksWrapper>
        <SnsWrapper>
          <SnSFacebook href='https://www.facebook.com/vertigogamesinc/?ref=br_rs'>
            <img src={facebookLogoImage} alt='Facebook Logo' />
          </SnSFacebook>
        </SnsWrapper>
      </FooterSecondRow>
    </FooterContainer>
  );
};

export default Footer;
