import React from 'react';
import { ReactComponent as Facebook } from '../../assets/images/acsy/ico_fb.svg';
import { ReactComponent as Youtube } from '../../assets/images/acsy/ico_yt.svg';
import { ReactComponent as Twitter } from '../../assets/images/acsy/ico_twitter_x.svg';
import { ReactComponent as Discord } from '../../assets/images/acsy/discord-seeklogo.com.svg';
import { ReactComponent as FacebookGroup } from '../../assets/images/acsy/ico_group.svg';
import { ReactComponent as Instagram } from '../../assets/images/acsy/ico_insta.svg';
import { ReactComponent as Twitch } from '../../assets/images/acsy/ico_twitch.svg';

import { SnsIconWrapper } from './sns-icon.styles';

const SnsIcon = ({ sns, link }) => {
    const setIcon = (sns) => {
        if (sns === 'facebook') {
            return <Facebook />;
        }
        if (sns === 'youtube') {
            return <Youtube />;
        }
        if (sns === 'twitter') {
            return <Twitter />;
        }
        if (sns === 'discord') {
            return <Discord />;
        }
        if (sns === 'facebookGroup') {
            return <FacebookGroup />;
        }
        if (sns === 'instagram') {
            return <Instagram />;
        }
        if (sns === 'twitch') {
            return <Twitch />;
        }
    };
    return (
        <SnsIconWrapper sns={sns} href={link} target="_blank">
            {setIcon(sns)}
        </SnsIconWrapper>
    );
};

export default SnsIcon;
