import { createSelector } from 'reselect';

const selectCareers = (state) => state.careers;

export const selectLifeOfVertigoImageTop = createSelector(
  [selectCareers],
  (careers) => careers.lifeOfVertigoIconTop
);

export const selectLifeOfVertigoImageBottom = createSelector(
  [selectCareers],
  (careers) => careers.lifeOfVertigoIconBottom
);

export const selectCircleData = createSelector(
  [selectCareers],
  (careers) => careers.circleData
);

export const selectOfficeFilter = createSelector(
  [selectCareers],
  (careers) => careers.officeFilterData
);

export const selectMobileFilterMenu = createSelector(
  [selectCareers],
  (careers) => careers.mobileFilterMenu
);
