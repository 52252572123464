import React from 'react';
import { useIntl } from 'react-intl';
import { Spring, animated } from 'react-spring';
//import TrailAnimation from '../trail-animation/trail-animation.component';
import HistoryElement from '../history-element/history-element.component';
import HistoryCharacters from '../history-character/history-character.component';

import {
  HistoryTableContainer,
  HistoryLineVertical,
} from './history-table.styles';

const HistoryTable = ({ isVisible }) => {
  const intl = useIntl();
  const historyData = intl.messages['history_data'];
  return (
    <HistoryTableContainer>
      <HistoryLineVertical />
      {isVisible
        ? historyData.map((data, index) => (
            <Spring
              key={index}
              delay={0 + index * 300}
              from={{ opacity: 0 }}
              to={{ opacity: 1 }}
            >
              {(styles) => (
                <animated.div style={styles}>
                  <HistoryElement historyData={data}></HistoryElement>
                </animated.div>
              )}
            </Spring>
          ))
        : null}
      <HistoryCharacters isVisible={isVisible} />
    </HistoryTableContainer>
  );
};

export default HistoryTable;
