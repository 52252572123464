import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';

import { selectEmailAddressList } from '../../redux/contactsPage/contactsPage.selector';

import PageSectionTitle from '../page-section-title/page-section-title.component';
import ContactsEmail from '../contacts-email/contacts-email.component';

import {
  ContactsQuestionsContainer,
  QuestionsWrapper,
  QuestionsEmailContainer,
} from './contacts-questions.styles';

const ContactsQuestions = ({ emailAddressList }) => {
  return (
    <ContactsQuestionsContainer>
      <PageSectionTitle
        title='contactspage_section_1_title'
        linePosition='center'
      />
      <QuestionsWrapper>
        <FormattedMessage
          id='contactspage_contact_desc'
          values={{ linebreak: <br />, b: (...chunks) => <b>{chunks}</b> }}
        />
      </QuestionsWrapper>
      <QuestionsEmailContainer>
        {emailAddressList.map(({ title, email }, index) => (
          <ContactsEmail key={index} title={title} email={email} />
        ))}
      </QuestionsEmailContainer>
    </ContactsQuestionsContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  emailAddressList: selectEmailAddressList,
});

export default connect(mapStateToProps)(ContactsQuestions);
