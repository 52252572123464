import { createSelector } from 'reselect';

const selectPosts = (state) => state.posts;

export const selectIsPostFetching = createSelector(
  [selectPosts],
  (posts) => posts.isFetching
);

export const selectAllPostItems = createSelector(
  [selectPosts],
  (posts) => posts.postsData
);

export const selectNewsItems = createSelector(
  [selectAllPostItems],
  (postsData) =>
    postsData.length > 0
      ? postsData.filter((data) => data.news_tag.includes('news'))
      : []
);

export const selectSeoulPositionPosts = createSelector(
  [selectAllPostItems],
  (postsData) =>
    postsData.length > 0
      ? postsData.filter((post) => post.categories.includes(5))
      : []
);

export const selectVancouverPositionPosts = createSelector(
  [selectAllPostItems],
  (postsData) =>
    postsData.length > 0
      ? postsData
          .filter(({ categories }) => categories.includes(3))
          .map((post) => ({ ...post, isActive: false }))
      : []
);

export const selectSingaporePositionPosts = createSelector(
  [selectAllPostItems],
  (postsData) =>
    postsData.length > 0
      ? postsData
          .filter(({ categories }) => categories.includes(4))
          .map((post) => ({ ...post, isActive: false }))
      : []
);
