import styled from 'styled-components';

export const ButtonContainer = styled.a`
  margin-left: auto;
  margin-right: auto;
  //margin-top: 3rem;
  //margin-bottom: 9.25rem;
  width: 100%;
  max-width: 23.6875rem;
  height: 4.375rem;
  border-radius: 2.5rem;
  background-color: #29b06d;
  box-shadow: 5px 7px 15px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  &:hover {
    box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.3);
  }
  @media ${({ theme }) => theme.tablet} {
    margin-top: 3rem;
  }
  @media ${({ theme }) => theme.mobile} {
    max-width: 220px;
    height: 50px;
  }
`;

export const PapayaLogoImg = styled.img`
  width: 11.25rem;
  @media ${({ theme }) => theme.mobile} {
    width: 108px;
    margin-right: 12px;
  }
`;

export const ButtonText = styled.span`
  margin-left: 0.75rem;
  margin-right: 1rem;
  font-family: ${(props) => props.theme.notoSansKr};
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5rem;
  color: #fff;
  @media ${({ theme }) => theme.mobile} {
    display: none;
  }
`;
