import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { IntlProvider } from 'react-intl';

import { selectActiveLocale } from '../../redux/locales/locales.selector';

import Korean from '../../assets/locale/ko.json';
import English from '../../assets/locale/en.json';

const setLocaleFile = (activeLocale) => {
  if (activeLocale === 'ko') {
    return { message: Korean, locale: 'ko' };
  }
  return { message: English, locale: 'en' };
};

const LocaleWrapper = ({ activeLocale, children }) => {
  return (
    <IntlProvider
      messages={setLocaleFile(activeLocale).message}
      locale={setLocaleFile(activeLocale).locale}
      onError={(err) => console.log(err)}
    >
      {children}
    </IntlProvider>
  );
};

const mapStateToProps = createStructuredSelector({
  activeLocale: selectActiveLocale,
});

export default connect(mapStateToProps)(LocaleWrapper);
