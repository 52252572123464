import GnbActionTypes from './gnb.types';

const INITIAL_STATE = {
  mobileMenu: false,
  menuList: [
    {
      name: 'About Us',
      link: '/about',
    },
    {
      name: 'Games',
      link: '/games',
    },
    {
      name: 'News',
      link: '/news',
    },
    {
      name: 'Careers',
      link: '/careers',
    },
    {
      name: 'Contacts',
      link: '/contacts',
    },
  ],
};

const gnbReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GnbActionTypes.HANDLE_MOBILE_GNB_MENU:
      return {
        ...state,
        mobileMenu: !state.mobileMenu,
      };
    default:
      return state;
  }
};

export default gnbReducer;
