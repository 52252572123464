import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import PageTopImage from '../../components/page-top-image/page-top-image.component';
import NewsContentContainer from '../../components/news-contents/news-contents.container';

import { selectNewsItems } from '../../redux/posts/posts.selector';

import { fetchPostStart } from '../../redux/posts/posts.actions';

import { NewsPageContainer } from './NewsPage.styles';

const NewsPage = ({ match, fetchPostStart, newsItems }) => {
  useEffect(() => {
    //console.log(newsItems);
    if (newsItems.length <= 0) {
      fetchPostStart();
    }
  });
  return (
    <NewsPageContainer>
      <PageTopImage path={match.path} />
      <NewsContentContainer />
    </NewsPageContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  newsItems: selectNewsItems,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPostStart: () => dispatch(fetchPostStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsPage);
