import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  LifeOfVertigoListWrapper,
  LifeOfVertigoHeader,
  LifeOfVertigoListNumber,
  LifeOfVertigoListTitle,
  LifeOfVertigoListDesc,
} from './lifeOfVertigoList.styles';

const LifeOfVertigoList = ({ number, title, desc, marginTop }) => {
  return (
    <LifeOfVertigoListWrapper marginTop={marginTop}>
      <LifeOfVertigoHeader>
        <LifeOfVertigoListNumber>{number}</LifeOfVertigoListNumber>
        <LifeOfVertigoListTitle>
          <FormattedMessage id={title} />
        </LifeOfVertigoListTitle>
      </LifeOfVertigoHeader>
      {desc ? (
        <LifeOfVertigoListDesc>
          <FormattedMessage id={desc} />
        </LifeOfVertigoListDesc>
      ) : null}
    </LifeOfVertigoListWrapper>
  );
};

export default LifeOfVertigoList;
