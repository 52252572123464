import styled, { css } from 'styled-components';
import checkmark_blk from '../../assets/images/acsy/ic_check_gnb_l_blk.png';
import checkmark_white from '../../assets/images/acsy/ic_check_gnb_l_w.png';

export const LocaleMenuContainer = styled.div`
  display: none;
  transform: translate(0px, 70px);
  position: absolute;
  width: 150px;
  height: 100px;
  border-radius: 5px;
  background-color: #f2f2f2;
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.3);
  margin: 0;
  list-style: none;
  white-space: nowrap;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
  padding-left: 0;
  padding-top: 16px;
  padding-bottom: 16px;
  @media ${({ theme }) => theme.tablet} {
    position: relative;
    display: flex;
    box-shadow: none;
    width: 100%;
    transform: none;
    background-color: transparent;
    flex-direction: row;
    align-items: center;
    //height: 30px;
  }
`;

export const LocaleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
  @media ${({ theme }) => theme.tablet} {
    width: 50%;
    &:nth-child(1) {
      border-right: 1px solid #54565a;
    }
  }
`;

export const CheckMark = styled.div`
  width: 14px;
  height: 10px;
  background-repeat: no-repeat;
  background-image: url(${({ active }) => (active ? checkmark_blk : '')});
  @media ${({ theme }) => theme.tablet} {
    background-image: url(${({ active }) =>
      active ? checkmark_white : checkmark_blk});
  }
`;

export const LocaleText = styled.h5`
  text-transform: uppercase;
  font-family: ${(props) => props.theme.notoSansRegular};
  font-size: 0.875rem;
  line-height: 1.1875rem;
  color: ${(props) => (props.active ? '#1c1c1c' : '#858585')};
  padding: 1px 6px;
  @media ${({ theme }) => theme.tablet} {
    font-size: 18px;
    line-height: 24px;
    color: ${({ active }) => (active ? '#f2f2f2' : '#54565A')};
    margin-left: 14px;
  }
`;
