import { createGlobalStyle } from 'styled-components';

import { FontFace } from './base/fonts';
import { Base } from './base/base';
import { GlobalModalStyle } from './base/modal';

const GlobalStyle = createGlobalStyle`
  ${FontFace}
  ${Base}
  ${GlobalModalStyle}
`;

export default GlobalStyle;
