import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

// gnb
const tabletGnbElementContatinerStyle = css`
  padding: 0 45px;
  max-width: 603px;
  flex-direction: column;
`;

// footer
const tabletFooterElementContatinerStyle = css`
  margin-top: 28px;
  max-width: 420px;
  flex-direction: row;
`;

const getElementContainerStyle = ({ footer }) => {
  if (footer) return tabletFooterElementContatinerStyle;
  return tabletGnbElementContatinerStyle;
};

export const GnbElementContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: ${({ footer }) => (footer ? '627px' : '603px')};

  @media ${({ theme }) => theme.tablet} {
    ${getElementContainerStyle}
  }
`;

const tabletGnbLinkElementWrapperStyle = css`
  width: 100%;
  padding: 23px 0 23px 15px;
  border-top: solid 1px #54565a;
  align-items: flex-start;
  &:nth-child(5) {
    border-bottom: solid 1px #54565a;
  }
`;

// link Element wrapper style
const getTabletLinkElementWrapperStyle = ({ footer }) => {
  if (!footer) return tabletGnbLinkElementWrapperStyle;
  return;
};

export const LinkElementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  @media ${({ theme }) => theme.tablet} {
    ${getTabletLinkElementWrapperStyle}
  }
`;

// link element style
const getColor = ({ footer, location, link }) => {
  if (footer) {
    return { color: '#858585' };
  } else {
    if (location === link) {
      return { color: '#fff' };
    }
    return { color: '#bbb' };
  }
};

const tabletGnbLinkElementStyle = css`
  font-size: 22px;
  line-height: 30px;
  color: ${({ location, link }) => (location === link ? '#FF8300' : '#bbb')};
`;

const tabletFooterLinkElementStyle = css`
  font-size: 14px;
  line-height: 19px;
`;

const getTabletLinkElement = ({ footer }) => {
  if (!footer) return tabletGnbLinkElementStyle;
  return tabletFooterLinkElementStyle;
};

export const LinkElement = styled(Link)`
  position: relative;
  display: inline-block;
  ${getColor};
  font-family: ${({ theme }) => theme.notoSansLight};
  font-size: 1.0625rem;
  line-height: 1.438rem;
  &:hover {
    color: ${({ footer }) => (footer ? '' : '#fff')};
  }
  @media ${({ theme }) => theme.tablet} {
    width: 100%;
    ${getTabletLinkElement};
  }
`;

export const ActiveMenuBar = styled.div`
  display: ${({ location, link, footer }) =>
    location === link && !footer ? 'block' : 'none'};
  position: absolute;
  bottom: -14px;
  height: 2px;
  left: 50%;
  width: 30px;
  background-color: #ff8300;
  margin: auto;
  transform: translate(-50%);
  @media ${({ theme }) => theme.tablet} {
    display: none;
  }
`;
