import styled from 'styled-components';

export const HirePositionContainer = styled.div`
  margin-top: 10px;
  padding-bottom: 50px;
  position: relative;
  width: 100%;
  min-height: 50rem;
  background-color: #f6f6f6;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  @media ${({ theme }) => theme.tablet} {
    padding: 0 60px;
  }
`;
