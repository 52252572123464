import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import GnbElement from '../../gnb-element/gnb-element.component';
import LocaleMenu from '../../../locale-menu/locale-menu.component';

import { selectMobileMenu } from '../../../../redux/gnb/gnb.selectors';

import { MobileMenuContainer, VertigoLogo } from './gnb-mobile-menu.styles';

const GnbMobileMenu = ({ mobileMenu }) => {
  return (
    <MobileMenuContainer mobileMenu={mobileMenu}>
      <VertigoLogo to='/' />
      <GnbElement />
      <LocaleMenu />
    </MobileMenuContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  mobileMenu: selectMobileMenu,
});

export default connect(mapStateToProps)(GnbMobileMenu);
