import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';

import GNB from '../components/GNB/gnb.component';
import MainPage from './MainPage/MainPage.component';
import AboutUsPage from './AboutUsPage/AboutUsPage.component';
import GamesPage from './GamesPage/GamesPage.component';
import NewsPage from './NewsPage/NewsPage.component';
import CareersPage from './CareersPage/CareersPage.component';
import ContactsPage from './ContactsPage/ContactsPage.component';
import FooterNavigateTop from '../components/footer-navigate-top/footer-navigate-top.component';
import Footer from '../components/footer/footer.component';

import { AppContainer, FooterWrapper } from './Routes.styles';

const RoutesNav = () => {
  const { pathname } = useLocation();
  return (
    <AppContainer pathname={pathname}>
      <GNB />
      <Switch>
        <Route exact path='/' component={MainPage} />
        <Route path='/about' component={AboutUsPage} />
        <Route path='/games' component={GamesPage} />
        <Route path='/news' component={NewsPage} />
        <Route path='/careers' component={CareersPage} />
        <Route path='/contacts' component={ContactsPage} />
      </Switch>
      <FooterWrapper pathname={pathname}>
        <FooterNavigateTop />
        <Footer />
      </FooterWrapper>
      <CookieConsent location='bottom' expires={150}>
        We use cookies to personalize and enhance your experience on our site.
        By using our site, you agree to our use of cookies.
      </CookieConsent>
    </AppContainer>
  );
};

export default RoutesNav;
