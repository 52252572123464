import styled from 'styled-components';

export const MainNewsSectionContainer = styled.div`
  margin-top: 15rem;
  margin-bottom: 15rem;

  //height: 100vh;
  /* scroll-snap-align: center;
  scroll-margin-top: 20rem;
  @media ${({ theme }) => theme.mobile} {
    scroll-margin-top: 8rem;
    padding-top: 3rem;
  } */
`;
