import styled, { css } from 'styled-components';

export const AboutUsContainer = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
`;

export const AboutUsDescriptionContainer = styled.div`
  position: relative;
  margin-bottom: 120px;
  margin-top: 142px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 0 60px;
  @media ${({ theme }) => theme.mobile} {
    padding-left: 0 40px;
  }
`;

const descriptionStyles = css`
  margin-top: 1.75rem;
  padding-left: 2.6875rem;
  width: 100%;
  max-width: 62.5rem;
  font-family: ${({ theme }) => theme.notoSansKr};
  font-weight: 300;
  font-size: 1rem;
  line-height: 2.3125rem;
  color: #1c1c1c;
  @media ${({ theme }) => theme.mobile} {
    margin-top: 2rem;
    padding-left: 0;
  }
`;
export const AboutUsDescription = styled.p`
  ${descriptionStyles}
`;

// section two
export const ServicingContainer = styled.div`
  width: 100%;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 9.25rem 60px;
  @media ${({ theme }) => theme.mobile} {
    padding: 65px 40px;
  }
`;

export const ServicingDescription = styled.p`
  ${descriptionStyles}
`;

export const AboutUsSixBoxContainer = styled.div`
  margin-top: 7.3125rem;
  margin-bottom: 7.5rem;
  width: 100%;
  max-width: 62.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 53px 0px;

  @media ${({ theme }) => theme.tablet} {
    grid-template-columns: 1fr 1fr;
  }
  @media ${({ theme }) => theme.mobile} {
    margin-top: 70px;
    grid-template-columns: 1fr;
    margin-bottom: 0;
  }
`;

export const AboutUsHistoryContainer = styled.div`
  padding: 8.125rem 60px 0 60px;
  position: relative;
  width: 100%;

  background-color: #fff;
  > div {
    width: 100%;
  }
  @media ${({ theme }) => theme.mobile} {
    padding: 108px 40px 0 40px;
  }
`;

export const HistoryInnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
