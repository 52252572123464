import React from 'react';

import { StepListWrapper, StepText } from './step-list.styles';

const StepList = ({ messages }) => {
  return (
    <StepListWrapper>
      {messages.map((message, index) => (
        <StepText key={index}>{message}</StepText>
      ))}
    </StepListWrapper>
  );
};

export default StepList;
