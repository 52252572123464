import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { enableOffice } from '../../redux/careersPage/careersPage.actions';

import {
  selectSeoulPositionPosts,
  selectVancouverPositionPosts,
  selectSingaporePositionPosts,
} from '../../redux/posts/posts.selector';

import { selectOfficeFilter } from '../../redux/careersPage/careersPage.selector';

import {
  HirePositionFilterContainer,
  FilterElement,
} from './hire-position-filter.styles';

const HirePositionFilter = ({
  officeFilter,
  seoulPositionPosts,
  vancouverPositionPosts,
  singaporePositionPosts,
  enableFilterMenu,
}) => {
  const officeFilterData = officeFilter;
  officeFilterData[0].number = seoulPositionPosts.length;
  officeFilterData[1].number = vancouverPositionPosts.length;
  officeFilterData[2].number = singaporePositionPosts.length;
  return (
    <HirePositionFilterContainer>
      {officeFilter.map(({ office, number, isActive }, index) => (
        <FilterElement
          key={index}
          isActive={isActive}
          onClick={() => enableFilterMenu(index)}
        >
          {office} ({number})
        </FilterElement>
      ))}
    </HirePositionFilterContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  seoulPositionPosts: selectSeoulPositionPosts,
  vancouverPositionPosts: selectVancouverPositionPosts,
  singaporePositionPosts: selectSingaporePositionPosts,
  officeFilter: selectOfficeFilter,
});

const mapDispatchToProps = (dispatch) => ({
  enableFilterMenu: (index) => dispatch(enableOffice(index)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HirePositionFilter);
