import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { connect } from 'react-redux';

import { selectItemId } from '../../redux/modal/modal.selector';
import { selectAllPostItems } from '../../redux/posts/posts.selector';

import { closeModal } from '../../redux/modal/modal.actions';

import {
  HirePositionModalContainer,
  CloseButtonWrapper,
  CloseButton,
  ModalHeader,
  HeaderTeam,
  HeaderPositionLocationWrapper,
  Position,
  Location,
  HeaderDivLine,
  ContentsBody,
} from './hire-position-modal.styles';
import { createStructuredSelector } from 'reselect';

const HirePositionModal = ({ closeModal, itemId, postItems }) => {
  const filteredPost = () => postItems.filter((post) => post.id === itemId);
  const post = filteredPost()[0];
  return (
    <HirePositionModalContainer>
      <CloseButtonWrapper>
        <CloseButton onClick={() => closeModal()} />
      </CloseButtonWrapper>

      <ModalHeader>
        <HeaderTeam>{parse(post.title.rendered)}</HeaderTeam>
        <HeaderPositionLocationWrapper>
          <Position>{post.hire_department} /</Position>
          <Location> {post.hire_location[0]}</Location>
        </HeaderPositionLocationWrapper>
      </ModalHeader>
      <HeaderDivLine />
      <ContentsBody>{parse(post.content.rendered)}</ContentsBody>
    </HirePositionModalContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  postItems: selectAllPostItems,
  itemId: selectItemId,
});

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HirePositionModal);
