import React from 'react';
import { useLocation } from 'react-router-dom';

import {
  NavigateToTopContainer,
  TopButtonButton,
  ArrowUp,
  TopText,
} from './footer-navigate-top.styles';

import scrollArrow from '../../assets/images/acsy/ic_arrowscroll_top_b.png';

const FooterNavigateTop = () => {
  const { pathname } = useLocation();
  const navigateToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <NavigateToTopContainer location={pathname}>
      <TopButtonButton onClick={() => navigateToTop()}>
        <ArrowUp src={scrollArrow} alt='navigate to top' />
        <TopText>TOP</TopText>
      </TopButtonButton>
    </NavigateToTopContainer>
  );
};

export default FooterNavigateTop;
