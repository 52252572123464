import React from 'react';
import parse from 'html-react-parser';

import {
  HistoryElementContainer,
  HistoryElementHeader,
  HistoryTip,
  HistoryYear,
  HistoryContainer,
  HistoryContentWrapper,
  HistoryMonth,
  HistoryTitle,
} from './history-element.styles';

const HistoryElement = ({ historyData }) => {
  const { year, contents } = historyData;
  return (
    <HistoryElementContainer>
      <HistoryElementHeader>
        <HistoryTip>-</HistoryTip>
        <HistoryYear>{year}</HistoryYear>
      </HistoryElementHeader>
      <HistoryContainer>
        {contents.map(({ month, topic }, index) => (
          <HistoryContentWrapper key={index}>
            <HistoryMonth month={month} index={index}>
              {month}
            </HistoryMonth>
            <HistoryTitle>{parse(topic)}</HistoryTitle>
          </HistoryContentWrapper>
        ))}
      </HistoryContainer>
    </HistoryElementContainer>
  );
};

export default HistoryElement;
