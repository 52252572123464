import PostsActionTypes from './posts.types';

export const fetchPostStart = () => ({
  type: PostsActionTypes.FETCH_POSTS_START,
});

export const fetchPostSuccess = (posts) => ({
  type: PostsActionTypes.FETCH_POSTS_SUCCESS,
  payload: posts,
});

export const fetchPostFailure = (errorMessage) => ({
  type: PostsActionTypes.FETCH_POSTS_FAILURE,
  payload: errorMessage,
});
