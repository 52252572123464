export const FontFace = `
@font-face {
  font-family: Noto Sans Thin;
  src: url('https://vertigogames.s3.amazonaws.com/static/assetsv2/font/NotoSans-Thin.ttf');
  font-weight: 100;
}
@font-face {
  font-family: Noto Sans Extra Light;
  src: url('https://vertigogames.s3.amazonaws.com/static/assetsv2/font/NotoSans-ExtraLight.ttf');
  font-weight: 200;
}
@font-face {
  font-family: Noto Sans Light;
  src: url('https://vertigogames.s3.amazonaws.com/static/assetsv2/font/NotoSans-Light.ttf');
  font-weight: 300;
}
@font-face {
  font-family: Noto Sans Regular;
  src: url('https://vertigogames.s3.amazonaws.com/static/assetsv2/font/NotoSans-Regular.ttf');
  font-weight: 400;
}
@font-face {
  font-family: Noto Sans Medium;
  src: url('https://vertigogames.s3.amazonaws.com/static/assetsv2/font/NotoSans-Medium.ttf');
  font-weight: 500;
}
@font-face {
  font-family: Noto Sans Bold;
  src: url('https://vertigogames.s3.amazonaws.com/static/assetsv2/font/NotoSans-Bold.ttf');
  font-weight: 700;
}
`;
