import styled from 'styled-components';

export const GamesPageContainer = styled.div``;

export const GamesContainer = styled.div`
  margin-top: 8.75rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-self: center;
  align-self: center;
  width: 100%;
  max-width: 1010px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 40px 30px;
  @media ${({ theme }) => theme.tablet} {
    grid-template-columns: 1fr 1fr;
    max-width: 495px;
  }
  @media ${({ theme }) => theme.mobile} {
    grid-template-columns: 1fr;
    max-width: 233px;
  }
`;
