import styled from 'styled-components';

export const AppContainer = styled.div`
  /* overflow: ${({ pathname }) => (pathname === '/' ? 'scroll' : 'auto')};
  scroll-snap-type: ${({ pathname }) =>
    pathname === '/' ? 'y mandatory' : 'none'};
  -webkit-overflow-scrolling: touch;
  height: ${({ pathname }) => (pathname === '/' ? '100vh' : 'auto')}; */
`;

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* scroll-snap-align: ${({ pathname }) =>
    pathname === '/' ? 'center' : 'unset'}; */
`;
