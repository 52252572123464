import aboutUsTopImage from '../../assets/images/bg/bg_main_about2.png';
import careersTopImage from '../../assets/images/bg/bg_main_careers.png';
import contactsTopImage from '../../assets/images/bg/bg_main_contact.png';
import gamesTopImage from '../../assets/images/bg/bg_main_games.png';
import newsTopImage from '../../assets/images/bg/bg_main_news.png';

const INITIAL_STATE = {
  pageTopContentItems: [
    {
      path: '/about',
      backgroundImage: aboutUsTopImage,
      title: 'BRINGING THE PLEASANT SENSATION OF VERTIGO TO GAMING',
    },
    {
      path: '/games',
      backgroundImage: gamesTopImage,
      title: 'STAYING TRUE TO OUR PASSION WE LOVE MAKING GAMES',
    },
    {
      path: '/news',
      backgroundImage: newsTopImage,
      title: 'VERTIGO GAMES IN THE PRESS',
    },
    {
      path: '/careers',
      backgroundImage: careersTopImage,
      title: 'JOIN VERTIGO GAMES',
    },
    {
      path: '/contacts',
      backgroundImage: contactsTopImage,
      title: 'CONTACT US',
    },
  ],
};

const pageTopContentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default pageTopContentReducer;
