import styled from 'styled-components';

export const MainGameSectionContainer = styled.div`
  //margin-top: 5rem;
  //height: 100vh;
  /* scroll-snap-align: center;
  scroll-margin-top: 28rem; */
  &:nth-child(2) {
    margin-top: 15rem;
  }

  @media ${({ theme }) => theme.laptop} {
    //scroll-margin-top: 18rem;
  }
  @media ${({ theme }) => theme.mobile} {
    /* scroll-margin-top: 8rem;
    padding-top: 3rem; */
  }
`;
